
import { ApiExpenseLineDto, ApiGetCostDto } from "@/api/generated/Api";
import CourseRefundDurationInput from "@/components/courses/section/CourseSectionRefund/RefundFormComponents/CourseRefundDurationInput.vue";
import { TravelAndExpenseType } from "@/shared/enums/courseRefund.enum";
import { createExpenseLine } from "@/shared/helpers/courseRefundHelpers";
import { getTimeDifferenceInMinutes, convertMinutesToHours } from "@/shared/helpers/dateHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useRoute } from "@/shared/useHelpers";
import { PropType, computed, defineComponent, onMounted, ref, watch } from "@vue/composition-api";

export default defineComponent({
  name: "CourseRefundDiet",
  components: {
    CourseRefundDurationInput,
  },
  props: {
    isInboundTravel: {
      type: Boolean,
      default: true,
    },
    costTypesDiet: {
      type: Array as PropType<ApiGetCostDto[]>,
    },
    editValues: {
      type: Object as PropType<ApiExpenseLineDto>,
    },
    defaultDates: {
      type: Object,
      required: true,
    },
  },
  emits: ["input"],
  setup({ editValues, costTypesDiet }, { emit }) {
    const formValues = ref<ApiExpenseLineDto>(editValues ?? createExpenseLine());
    const travelDuration = ref<number>(0);
    const travelDurationDisplay = ref("");
    const showSleepoverField = ref<boolean>(false);
    const arrival = ref<string>(editValues?.arrival ?? "");
    const departure = ref<string>(editValues?.departure ?? "");
    const route = useRoute();
    const refundClaimId = +route.params.refundClaimId;
    const keepDiet = ref(true);

    const setCostTypeId = () => {
      if (!keepDiet.value) {
        formValues.value.costId = 0;
        formValues.value.quantity = 0;
        return;
      }

      if (travelDuration.value > 720 && formValues.value.quantity) {
        if (formValues.value.quantity >= 1) {
          return (formValues.value.costId =
            costTypesDiet?.find((x) => x.travelAndExpenseType === TravelAndExpenseType.DietWithAccomodation)?.id || 0);
        }
      }

      if (travelDuration.value > 720) {
        formValues.value.costId =
          costTypesDiet?.find((x) => x.travelAndExpenseType === TravelAndExpenseType.DietOverTwelveHours)?.id || 0;
      }

      if (travelDuration.value >= 360 && travelDuration.value <= 720) {
        formValues.value.costId =
          costTypesDiet?.find((x) => x.travelAndExpenseType === TravelAndExpenseType.DietBetweenSixAndTwelve)?.id || 0;
      }

      if (travelDuration.value < 360) {
        formValues.value.costId = 0;
      }
    };

    const setTravelDurationDisplay = () => {
      let hours = convertMinutesToHours(travelDuration.value).hours.toString();
      let minutes = convertMinutesToHours(travelDuration.value).minutes.toString();

      if (travelDuration.value >= 0) {
        let travelAndDurationString = "";

        if (+minutes < 10) {
          minutes = "0" + minutes;
        }

        if (+hours < 10) {
          hours = "0" + hours;
        }

        if (hours !== "00") {
          travelAndDurationString = `${hours} timer`;
        }

        if (minutes !== "00") {
          travelAndDurationString += ` og ${minutes} minutter`;
        }

        return travelAndDurationString;
      }

      return "Du kan ikke ha negativ reisetid!";
    };

    watch(travelDuration, () => {
      travelDurationDisplay.value = setTravelDurationDisplay();
      setCostTypeId();
    });

    watch(
      () => formValues.value.quantity,
      () => {
        setCostTypeId();
      }
    );

    watch(keepDiet, () => {
      setCostTypeId();
    });

    // Proper hack to handle if the form has food as a step, due to returning food always in getSelectedSteps if we have travelTo/from.
    const display = computed(() => {
      if (editValues && refundClaimId) {
        return true;
      }
      return !(!editValues && refundClaimId);
    });

    const validateMealsAllowed = (v: any) => {
      if (formValues.value.quantity) {
        return +v <= formValues.value.quantity || "Tillatt kun et måltid per overnatting";
      }
      return +v <= 1 || "Tillat kun et måltid";
    };

    watch(
      () => formValues.value,
      (expense) => {
        updateSleepoverVisibility();
        emitFormValues({ ...expense });
      },
      { deep: true }
    );

    // If duration is changed back to be less than 6 hours
    watch(travelDuration, (duration: number) => {
      if (duration < 6) {
        emitFormValues({});
      }
    });

    const emitFormValues = (value: any) => {
      emit("input", value);
    };

    const updateFormValues = (value: any): void => {
      formValues.value = { ...formValues.value, ...value };
    };

    const hasSelectedAccomodation = (): boolean => {
      const costType = costTypesDiet?.find((x) => x.id === formValues.value.costId);

      if (!costType) {
        return false;
      }

      return costType.travelAndExpenseType === TravelAndExpenseType.DietWithAccomodation;
    };

    const updateSleepoverVisibility = () => {
      if (hasSelectedAccomodation()) {
        showSleepoverField.value = true;
      } else {
        showSleepoverField.value = false;
        formValues.value.quantity = null;
      }
    };

    onMounted(() => {
      showSleepoverField.value = hasSelectedAccomodation();
      if (arrival.value && departure.value) {
        travelDuration.value = getTimeDifferenceInMinutes(departure.value, arrival.value) || 0;
      }
    });

    return {
      validateNotEmpty,
      emitFormValues,
      updateFormValues,
      display,
      formValues,
      travelDuration,
      arrival,
      departure,
      showSleepoverField,
      isEditing: computed(() => refundClaimId && refundClaimId > -1),
      validateMealsAllowed,
      keepDiet,
      travelDurationDisplay,
    };
  },
});
