var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-file-input', {
    attrs: {
      "rules": _vm.disableValidation ? [] : [_vm.validateArrayNotEmpty],
      "accept": "pdf, .jpeg, .jpg, .png",
      "chips": "",
      "label": _vm.label,
      "multiple": "",
      "show-size": "",
      "messages": _vm.helpText,
      "clearable": false,
      "loading": _vm.isUploadingFiles
    },
    on: {
      "change": _vm.uploadReceipts
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var index = _ref.index,
          text = _ref.text,
          file = _ref.file;
        return [file.isLoading === false ? _c('v-chip', {
          attrs: {
            "close": "",
            "left": ""
          },
          on: {
            "click:close": function ($event) {
              return _vm.deleteFile(index);
            }
          }
        }, [_vm._v(" " + _vm._s(text) + " ")]) : _vm._e(), file.isLoading ? _c('v-chip', [_vm._v(" " + _vm._s(text) + " "), _c('v-progress-circular', {
          attrs: {
            "size": 15,
            "width": "2",
            "indeterminate": "",
            "color": "primary",
            "left": ""
          }
        })], 1) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.files,
      callback: function ($$v) {
        _vm.files = $$v;
      },
      expression: "files"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }