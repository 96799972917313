var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.file ? _c('div', [_c('hr'), _c('v-row', {
    staticClass: "pt-2 pb-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('a', {
    staticClass: "v-btn mx-2",
    attrs: {
      "target": "_blank",
      "href": _vm.fileUrl
    }
  }, [_vm._v("Åpne vedlegg "), _c('v-icon', [_vm._v("mdi-open-in-new")])], 1)]), _vm.displayFileSize ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('BaseInfoField', {
    attrs: {
      "label": "Størrelse",
      "value": _vm.getSize(_vm.file)
    }
  })], 1) : _vm._e()], 1)], 1) : _c('v-progress-circular', {
    staticClass: "pa-4",
    attrs: {
      "size": 30,
      "width": "2",
      "indeterminate": "",
      "color": "primary",
      "left": ""
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }