
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import BaseAddressFields from "@/components/shared/adress/BaseAdressFields.vue";
import { defineComponent, ref } from "@vue/composition-api";

enum AddressTypes {
  Destination = "destination",
  Origin = "origin",
}

interface AddressInterface {
  addressLine: string;
  postalCode: string;
  postalArea: string;
  country: string;
}

export default defineComponent({
  name: "CourseRefundAddressInput",
  components: { BaseAddressFields },
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  emits: ["input"],
  setup(props, { emit }) {
    const addressValues = ref({
      [AddressTypes.Destination]: {
        addressLine: "",
        postalCode: "",
        postalArea: "",
        country: "",
      },
      [AddressTypes.Origin]: {
        addressLine: "",
        postalCode: "",
        postalArea: "",
        country: "",
      },
      ...props.address,
    });

    const emitFormValues = (key: AddressTypes, inputValue: AddressInterface) => {
      addressValues.value[key] = inputValue;
      emit("input", { ...addressValues.value });
    };

    return {
      addressValues,
      validateNotEmpty,
      emitFormValues,
      AddressTypes,
    };
  },
});
