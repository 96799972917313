var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('BaseDatePicker', {
    attrs: {
      "label": "Dato fra",
      "value": _vm.isInboundTravel ? _vm.defaultArrivalDate : _vm.defaultDepartureDate,
      "validate-not-empty": !_vm.keepDiet ? true : false,
      "disabled": !_vm.keepDiet
    },
    on: {
      "input": function (value) {
        return _vm.emitFormValues('departure', 'date', value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "required",
    attrs: {
      "type": "time",
      "rules": _vm.keepDiet ? [_vm.validateTimeInput, _vm.validateNotEmpty] : [],
      "label": "Tid fra",
      "disabled": !_vm.keepDiet
    },
    model: {
      value: _vm.dateValues.departure.time,
      callback: function ($$v) {
        _vm.$set(_vm.dateValues.departure, "time", $$v);
      },
      expression: "dateValues.departure.time"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('BaseDatePicker', {
    attrs: {
      "label": "Dato til",
      "value": _vm.isInboundTravel ? _vm.defaultArrivalDate : _vm.defaultDepartureDate,
      "validate-not-empty": !_vm.keepDiet ? true : false,
      "disabled": !_vm.keepDiet
    },
    on: {
      "input": function (inputValue) {
        return _vm.emitFormValues('arrival', 'date', inputValue);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "required",
    attrs: {
      "type": "time",
      "rules": _vm.keepDiet ? [_vm.validateTimeInput, _vm.validateNotEmpty] : [],
      "label": "Tid til",
      "disabled": !_vm.keepDiet
    },
    model: {
      value: _vm.dateValues.arrival.time,
      callback: function ($$v) {
        _vm.$set(_vm.dateValues.arrival, "time", $$v);
      },
      expression: "dateValues.arrival.time"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }