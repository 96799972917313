var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "Adresse",
      "name": "street",
      "rules": [_vm.validateNotEmpty]
    },
    model: {
      value: _vm.inputValues.addressLine,
      callback: function ($$v) {
        _vm.$set(_vm.inputValues, "addressLine", $$v);
      },
      expression: "inputValues.addressLine"
    }
  }), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Postnummer",
      "name": "postalCode",
      "rules": [_vm.validateNotEmpty]
    },
    model: {
      value: _vm.inputValues.postalCode,
      callback: function ($$v) {
        _vm.$set(_vm.inputValues, "postalCode", $$v);
      },
      expression: "inputValues.postalCode"
    }
  }), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "Poststed",
      "name": "city",
      "rules": [_vm.validateNotEmpty]
    },
    model: {
      value: _vm.inputValues.postalArea,
      callback: function ($$v) {
        _vm.$set(_vm.inputValues, "postalArea", $$v);
      },
      expression: "inputValues.postalArea"
    }
  }), _c('v-spacer'), _c('v-select', {
    attrs: {
      "label": "Land",
      "name": "country",
      "item-text": "name",
      "item-value": "ssbCode",
      "items": _vm.countries,
      "rules": [_vm.validateNotEmpty]
    },
    model: {
      value: _vm.inputValues.country,
      callback: function ($$v) {
        _vm.$set(_vm.inputValues, "country", $$v);
      },
      expression: "inputValues.country"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }