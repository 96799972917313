
import { computed, defineComponent, PropType, ref, onMounted } from "@vue/composition-api";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import CourseRefundFileUpload from "@/components/courses/section/CourseSectionRefund/RefundFormComponents/CourseRefundFileUpload.vue";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { ApiGetCostDto, ApiGetStipendDto, ApiStipendDto } from "@/api/generated/Api";
import { TravelAndExpenseType } from "@/shared/enums/courseRefund.enum";
export default defineComponent({
  name: "CourseRefundScholarship",
  components: { BaseDatePicker, CourseRefundFileUpload },
  props: {
    costTypes: {
      type: Array as PropType<ApiGetCostDto[]>,
      required: true,
    },
    stipend: {
      required: false,
      type: Object as PropType<ApiGetStipendDto>,
    },
    defaultDates: {
      type: Object,
      required: true,
    },
  },
  emits: ["submit", "previous-step"],
  setup({ stipend, costTypes, defaultDates }, { emit }) {
    const setCostTypeId = () => {
      return costTypes.filter((x) => x.travelAndExpenseType === TravelAndExpenseType.Stipend)[0].id;
    };

    const formValues = ref<ApiStipendDto>({
      costId: setCostTypeId(),
      attachmentIds: stipend?.attachments ? stipend?.attachments.map((x) => x.id) : [],
      description: null,
      hours: 0,
      toDate: "",
      fromDate: "",
      ...stipend,
    });

    const setDefaultDates = async () => {
      formValues.value.toDate ||= defaultDates.arrival;
      formValues.value.fromDate ||= defaultDates.arrival;
    };

    onMounted(async () => {
      await setDefaultDates();
    });

    const submit = () => {
      emit("submit", { stipend: formValues.value });
    };

    const updateFormValues = (value: ApiGetStipendDto) => {
      formValues.value = value;
    };

    const updateAttachments = (value: number[]) => {
      formValues.value.attachmentIds = value;
    };

    const costTypesStipend = computed(() => {
      return costTypes.filter((costType) => costType.travelAndExpenseType === TravelAndExpenseType.Stipend);
    });

    return {
      formValues,
      costTypesStipend,
      updateFormValues,
      submit,
      validateNotEmpty,
      updateAttachments,
    };
  },
});
