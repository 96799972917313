var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_vm._t("coTravelerName"), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('fieldset', [_c('legend', [_c('v-icon', [_vm._v("mdi-map-marker-outline")]), _c('b', [_vm._v("Fra adresse")])], 1), _c('BaseAddressFields', {
    attrs: {
      "address": _vm.addressValues.origin
    },
    on: {
      "input": function (inputValue) {
        return _vm.emitFormValues(_vm.AddressTypes.Origin, inputValue);
      }
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('fieldset', [_c('legend', [_c('v-icon', [_vm._v("mdi-map-marker-outline")]), _c('b', [_vm._v("Til adresse")])], 1), _c('BaseAddressFields', {
    attrs: {
      "address": _vm.addressValues.destination
    },
    on: {
      "input": function (inputValue) {
        return _vm.emitFormValues(_vm.AddressTypes.Destination, inputValue);
      }
    }
  })], 1)]), _vm._t("coTravelerDistance")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }