
import { isMobile } from "@/shared/helpers/displayHelpers";
import { useRouteComputed } from "@/shared/useHelpers";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BaseBreadCrumbs",
  setup() {
    const route = useRouteComputed();
    return {
      isMobile,
      breadcrumbList: computed(() => route.value.meta?.breadcrumb),
    };
  },
});
