
import { defineComponent, onMounted, ref, Ref, watch } from "@vue/composition-api";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
export default defineComponent({
  name: "BaseTimePicker",
  props: {
    value: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
  emits: ["input"],
  setup({ value }, { emit }) {
    const showMenu: Ref<boolean> = ref(false);
    const time = ref<string>();

    onMounted(() => {
      if (value) {
        time.value = value;
      }
    });

    watch(time, () => {
      emit("input", time.value);
    });

    return { showMenu, time, validateNotEmpty };
  },
});
