var render = function () {
  var _vm$mainFormValues, _vm$mainFormValues$ex, _vm$dietToExpense, _vm$dietToExpense2, _vm$dietToExpense3, _vm$dietToExpense4, _vm$mainFormValues2, _vm$dietToExpense5, _vm$dietToExpense6, _vm$dietToExpense7, _vm$dietToExpense8, _vm$dietToExpense9, _vm$dietToExpense10, _vm$mainFormValues3, _vm$mainFormValues3$e, _vm$dietFromExpense, _vm$dietFromExpense2, _vm$dietFromExpense3, _vm$dietFromExpense4, _vm$mainFormValues4, _vm$dietFromExpense5, _vm$dietFromExpense6, _vm$dietFromExpense7, _vm$dietFromExpense8, _vm$dietFromExpense9, _vm$dietFromExpense10, _vm$mainFormValues5, _vm$mainFormValues6, _vm$mainFormValues6$s, _vm$mainFormValues7, _vm$mainFormValues7$s, _vm$mainFormValues8, _vm$mainFormValues8$s, _vm$mainFormValues9, _vm$mainFormValues9$s, _vm$mainFormValues10, _vm$mainFormValues10$, _vm$mainFormValues11, _vm$mainFormValues12, _vm$mainFormValues12$, _vm$mainFormValues13, _vm$mainFormValues14;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', [_vm._v("Dette er en oppsummering av refusjonskravet")]), _c('v-card', {
    staticClass: "pa-4 mb-4",
    attrs: {
      "elevation": "2",
      "outlined": ""
    }
  }, [_vm.mainFormValues.bankAccountNo ? _c('BaseInfoField', {
    attrs: {
      "value": _vm.mainFormValues.bankAccountNo,
      "label": "Bankkontonummer"
    }
  }) : _vm._e(), _vm.mainFormValues.description ? _c('BaseInfoField', {
    attrs: {
      "value": _vm.mainFormValues.description,
      "label": "Kommentar"
    }
  }) : _vm._e(), _c('BaseInfoField', {
    attrs: {
      "value": _vm.formatCurrency(_vm.totalRefundAmount) || "Lagre utkast for å se total beløp",
      "label": "Total beløp"
    }
  })], 1)], 1)], 1), _c('v-row', [((_vm$mainFormValues = _vm.mainFormValues) !== null && _vm$mainFormValues !== void 0 && (_vm$mainFormValues$ex = _vm$mainFormValues.expenseLinesTo) !== null && _vm$mainFormValues$ex !== void 0 && _vm$mainFormValues$ex.length || (_vm$dietToExpense = _vm.dietToExpense) !== null && _vm$dietToExpense !== void 0 && _vm$dietToExpense.quantity || (_vm$dietToExpense2 = _vm.dietToExpense) !== null && _vm$dietToExpense2 !== void 0 && _vm$dietToExpense2.providedBreakfastQuantity || (_vm$dietToExpense3 = _vm.dietToExpense) !== null && _vm$dietToExpense3 !== void 0 && _vm$dietToExpense3.providedDinnerQuantity || (_vm$dietToExpense4 = _vm.dietToExpense) !== null && _vm$dietToExpense4 !== void 0 && _vm$dietToExpense4.providedLunchQuantity) && _vm.hasTravel ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('h2', [_vm._v("Reise til")]), _vm._l((_vm$mainFormValues2 = _vm.mainFormValues) === null || _vm$mainFormValues2 === void 0 ? void 0 : _vm$mainFormValues2.expenseLinesTo, function (expenseLine, i) {
    var _expenseLine$origin, _expenseLine$origin2, _expenseLine$origin3, _expenseLine$origin4, _expenseLine$origin5, _expenseLine$origin6, _expenseLine$destinat, _expenseLine$destinat2, _expenseLine$destinat3, _expenseLine$destinat4, _expenseLine$destinat5, _expenseLine$destinat6;
    return _c('v-card', {
      key: expenseLine.costId + i,
      staticClass: "pa-4 mb-4",
      attrs: {
        "elevation": "2",
        "outlined": ""
      }
    }, [_c('b', [_c('BaseInfoField', {
      attrs: {
        "label": "Utgiftstype",
        "value": _vm.getTypeName(expenseLine.costId)
      }
    })], 1), expenseLine.description ? _c('BaseInfoField', {
      attrs: {
        "label": "Kommentar",
        "value": expenseLine.description
      }
    }) : _vm._e(), expenseLine.amount ? _c('BaseInfoField', {
      attrs: {
        "label": "Beløp",
        "value": _vm.formatCurrency(expenseLine.amount)
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$origin = expenseLine.origin) !== null && _expenseLine$origin !== void 0 && _expenseLine$origin.addressLine ? _c('BaseInfoField', {
      attrs: {
        "label": "Fra adresse",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$origin2 = expenseLine.origin) === null || _expenseLine$origin2 === void 0 ? void 0 : _expenseLine$origin2.addressLine
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$origin3 = expenseLine.origin) !== null && _expenseLine$origin3 !== void 0 && _expenseLine$origin3.postalCode ? _c('BaseInfoField', {
      attrs: {
        "label": "Postnummer",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$origin4 = expenseLine.origin) === null || _expenseLine$origin4 === void 0 ? void 0 : _expenseLine$origin4.postalCode
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$origin5 = expenseLine.origin) !== null && _expenseLine$origin5 !== void 0 && _expenseLine$origin5.postalArea ? _c('BaseInfoField', {
      attrs: {
        "label": "Poststed",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$origin6 = expenseLine.origin) === null || _expenseLine$origin6 === void 0 ? void 0 : _expenseLine$origin6.postalArea
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$destinat = expenseLine.destination) !== null && _expenseLine$destinat !== void 0 && _expenseLine$destinat.addressLine ? _c('BaseInfoField', {
      attrs: {
        "label": "Til adresse",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$destinat2 = expenseLine.destination) === null || _expenseLine$destinat2 === void 0 ? void 0 : _expenseLine$destinat2.addressLine
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$destinat3 = expenseLine.destination) !== null && _expenseLine$destinat3 !== void 0 && _expenseLine$destinat3.postalCode ? _c('BaseInfoField', {
      attrs: {
        "label": "Postnummer",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$destinat4 = expenseLine.destination) === null || _expenseLine$destinat4 === void 0 ? void 0 : _expenseLine$destinat4.postalCode
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$destinat5 = expenseLine.destination) !== null && _expenseLine$destinat5 !== void 0 && _expenseLine$destinat5.postalArea ? _c('BaseInfoField', {
      attrs: {
        "label": "Poststed",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$destinat6 = expenseLine.destination) === null || _expenseLine$destinat6 === void 0 ? void 0 : _expenseLine$destinat6.postalArea
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && expenseLine.quantity ? _c('BaseInfoField', {
      attrs: {
        "label": "Distanse",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : expenseLine.quantity
      }
    }) : _vm._e(), expenseLine.providedBreakfastQuantity ? _c('BaseInfoField', {
      attrs: {
        "label": "Antall frokoster",
        "value": expenseLine.providedBreakfastQuantity
      }
    }) : _vm._e(), expenseLine.providedLunchQuantity ? _c('BaseInfoField', {
      attrs: {
        "label": "Antall lunsjer",
        "value": expenseLine.providedLunchQuantity
      }
    }) : _vm._e(), expenseLine.providedDinnerQuantity ? _c('BaseInfoField', {
      attrs: {
        "label": "Antall middager",
        "value": expenseLine.providedDinnerQuantity
      }
    }) : _vm._e(), _vm._l(_vm.getAttachmentIds(expenseLine), function (attachmentId) {
      return _c('div', {
        key: attachmentId
      }, [_c('CourseRefundDisplayFile', {
        attrs: {
          "fileId": attachmentId
        }
      })], 1);
    })], 2);
  }), _vm.dietToExpense ? _c('v-card', {
    staticClass: "pa-4 mb-4",
    attrs: {
      "elevation": "2",
      "outlined": ""
    }
  }, [_c('b', [_c('BaseInfoField', {
    attrs: {
      "label": "Utgiftstype",
      "value": _vm.getTypeName(_vm.dietToExpense.costId)
    }
  })], 1), _vm.dietToExpense.description ? _c('BaseInfoField', {
    attrs: {
      "label": "Kommentar",
      "value": _vm.dietToExpense.description
    }
  }) : _vm._e(), (_vm$dietToExpense5 = _vm.dietToExpense) !== null && _vm$dietToExpense5 !== void 0 && _vm$dietToExpense5.departure ? _c('BaseInfoField', {
    attrs: {
      "label": "Dato fra",
      "value": _vm.formatLocalizedDateTime((_vm$dietToExpense6 = _vm.dietToExpense) === null || _vm$dietToExpense6 === void 0 ? void 0 : _vm$dietToExpense6.departure)
    }
  }) : _vm._e(), (_vm$dietToExpense7 = _vm.dietToExpense) !== null && _vm$dietToExpense7 !== void 0 && _vm$dietToExpense7.arrival ? _c('BaseInfoField', {
    attrs: {
      "label": "Dato til",
      "value": _vm.formatLocalizedDateTime((_vm$dietToExpense8 = _vm.dietToExpense) === null || _vm$dietToExpense8 === void 0 ? void 0 : _vm$dietToExpense8.arrival)
    }
  }) : _vm._e(), (_vm$dietToExpense9 = _vm.dietToExpense) !== null && _vm$dietToExpense9 !== void 0 && _vm$dietToExpense9.quantity ? _c('BaseInfoField', {
    attrs: {
      "label": "Antall overnattinger",
      "value": (_vm$dietToExpense10 = _vm.dietToExpense) === null || _vm$dietToExpense10 === void 0 ? void 0 : _vm$dietToExpense10.quantity
    }
  }) : _vm._e(), _vm.dietToExpense.providedBreakfastQuantity ? _c('BaseInfoField', {
    attrs: {
      "label": "Antall frokoster",
      "value": _vm.dietToExpense.providedBreakfastQuantity
    }
  }) : _vm._e(), _vm.dietToExpense.providedLunchQuantity ? _c('BaseInfoField', {
    attrs: {
      "label": "Antall lunsjer",
      "value": _vm.dietToExpense.providedLunchQuantity
    }
  }) : _vm._e(), _vm.dietToExpense.providedDinnerQuantity ? _c('BaseInfoField', {
    attrs: {
      "label": "Antall middager",
      "value": _vm.dietToExpense.providedDinnerQuantity
    }
  }) : _vm._e()], 1) : _vm._e()], 2) : _vm._e(), ((_vm$mainFormValues3 = _vm.mainFormValues) !== null && _vm$mainFormValues3 !== void 0 && (_vm$mainFormValues3$e = _vm$mainFormValues3.expenseLinesFrom) !== null && _vm$mainFormValues3$e !== void 0 && _vm$mainFormValues3$e.length || (_vm$dietFromExpense = _vm.dietFromExpense) !== null && _vm$dietFromExpense !== void 0 && _vm$dietFromExpense.quantity || (_vm$dietFromExpense2 = _vm.dietFromExpense) !== null && _vm$dietFromExpense2 !== void 0 && _vm$dietFromExpense2.providedBreakfastQuantity || (_vm$dietFromExpense3 = _vm.dietFromExpense) !== null && _vm$dietFromExpense3 !== void 0 && _vm$dietFromExpense3.providedDinnerQuantity || (_vm$dietFromExpense4 = _vm.dietFromExpense) !== null && _vm$dietFromExpense4 !== void 0 && _vm$dietFromExpense4.providedLunchQuantity) && _vm.hasTravel ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('h2', [_vm._v("Reise fra")]), _vm._l((_vm$mainFormValues4 = _vm.mainFormValues) === null || _vm$mainFormValues4 === void 0 ? void 0 : _vm$mainFormValues4.expenseLinesFrom, function (expenseLine, i) {
    var _expenseLine$origin7, _expenseLine$origin8, _expenseLine$origin9, _expenseLine$origin10, _expenseLine$origin11, _expenseLine$origin12, _expenseLine$destinat7, _expenseLine$destinat8, _expenseLine$destinat9, _expenseLine$destinat10, _expenseLine$destinat11, _expenseLine$destinat12;
    return _c('v-card', {
      key: expenseLine.costId + i,
      staticClass: "pa-4 mb-4",
      attrs: {
        "elevation": "2",
        "outlined": ""
      }
    }, [_c('b', [_c('BaseInfoField', {
      attrs: {
        "label": "Utgiftstype",
        "value": _vm.getTypeName(expenseLine.costId)
      }
    })], 1), expenseLine.description ? _c('BaseInfoField', {
      attrs: {
        "label": "Kommentar",
        "value": expenseLine.description
      }
    }) : _vm._e(), expenseLine.amount ? _c('BaseInfoField', {
      attrs: {
        "label": "Beløp",
        "value": _vm.formatCurrency(expenseLine.amount)
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$origin7 = expenseLine.origin) !== null && _expenseLine$origin7 !== void 0 && _expenseLine$origin7.addressLine ? _c('BaseInfoField', {
      attrs: {
        "label": "Fra adresse",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$origin8 = expenseLine.origin) === null || _expenseLine$origin8 === void 0 ? void 0 : _expenseLine$origin8.addressLine
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$origin9 = expenseLine.origin) !== null && _expenseLine$origin9 !== void 0 && _expenseLine$origin9.postalCode ? _c('BaseInfoField', {
      attrs: {
        "label": "Postnummer",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$origin10 = expenseLine.origin) === null || _expenseLine$origin10 === void 0 ? void 0 : _expenseLine$origin10.postalCode
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$origin11 = expenseLine.origin) !== null && _expenseLine$origin11 !== void 0 && _expenseLine$origin11.postalArea ? _c('BaseInfoField', {
      attrs: {
        "label": "Poststed",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$origin12 = expenseLine.origin) === null || _expenseLine$origin12 === void 0 ? void 0 : _expenseLine$origin12.postalArea
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$destinat7 = expenseLine.destination) !== null && _expenseLine$destinat7 !== void 0 && _expenseLine$destinat7.addressLine ? _c('BaseInfoField', {
      attrs: {
        "label": "Til adresse",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$destinat8 = expenseLine.destination) === null || _expenseLine$destinat8 === void 0 ? void 0 : _expenseLine$destinat8.addressLine
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$destinat9 = expenseLine.destination) !== null && _expenseLine$destinat9 !== void 0 && _expenseLine$destinat9.postalCode ? _c('BaseInfoField', {
      attrs: {
        "label": "Postnummer",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$destinat10 = expenseLine.destination) === null || _expenseLine$destinat10 === void 0 ? void 0 : _expenseLine$destinat10.postalCode
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$destinat11 = expenseLine.destination) !== null && _expenseLine$destinat11 !== void 0 && _expenseLine$destinat11.postalArea ? _c('BaseInfoField', {
      attrs: {
        "label": "Poststed",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$destinat12 = expenseLine.destination) === null || _expenseLine$destinat12 === void 0 ? void 0 : _expenseLine$destinat12.postalArea
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && expenseLine.quantity ? _c('BaseInfoField', {
      attrs: {
        "label": "Distanse",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : expenseLine.quantity
      }
    }) : _vm._e(), expenseLine.providedBreakfastQuantity ? _c('BaseInfoField', {
      attrs: {
        "label": "Antall frokoster",
        "value": expenseLine.providedBreakfastQuantity
      }
    }) : _vm._e(), expenseLine.providedLunchQuantity ? _c('BaseInfoField', {
      attrs: {
        "label": "Antall lunsjer",
        "value": expenseLine.providedLunchQuantity
      }
    }) : _vm._e(), expenseLine.providedDinnerQuantity ? _c('BaseInfoField', {
      attrs: {
        "label": "Antall middager",
        "value": expenseLine.providedDinnerQuantity
      }
    }) : _vm._e(), _vm._l(_vm.getAttachmentIds(expenseLine), function (attachmentId) {
      return _c('div', {
        key: attachmentId
      }, [_c('CourseRefundDisplayFile', {
        attrs: {
          "fileId": attachmentId
        }
      })], 1);
    })], 2);
  }), _vm.dietFromExpense ? _c('v-card', {
    staticClass: "pa-4 mb-4",
    attrs: {
      "elevation": "2",
      "outlined": ""
    }
  }, [_c('b', [_c('BaseInfoField', {
    attrs: {
      "label": "Utgiftstype",
      "value": _vm.getTypeName(_vm.dietFromExpense.costId)
    }
  })], 1), _vm.dietFromExpense.description ? _c('BaseInfoField', {
    attrs: {
      "label": "Kommentar",
      "value": _vm.dietFromExpense.description
    }
  }) : _vm._e(), (_vm$dietFromExpense5 = _vm.dietFromExpense) !== null && _vm$dietFromExpense5 !== void 0 && _vm$dietFromExpense5.departure ? _c('BaseInfoField', {
    attrs: {
      "label": "Dato fra",
      "value": _vm.formatLocalizedDateTime((_vm$dietFromExpense6 = _vm.dietFromExpense) === null || _vm$dietFromExpense6 === void 0 ? void 0 : _vm$dietFromExpense6.departure)
    }
  }) : _vm._e(), (_vm$dietFromExpense7 = _vm.dietFromExpense) !== null && _vm$dietFromExpense7 !== void 0 && _vm$dietFromExpense7.arrival ? _c('BaseInfoField', {
    attrs: {
      "label": "Dato til",
      "value": _vm.formatLocalizedDateTime((_vm$dietFromExpense8 = _vm.dietFromExpense) === null || _vm$dietFromExpense8 === void 0 ? void 0 : _vm$dietFromExpense8.arrival)
    }
  }) : _vm._e(), (_vm$dietFromExpense9 = _vm.dietFromExpense) !== null && _vm$dietFromExpense9 !== void 0 && _vm$dietFromExpense9.quantity ? _c('BaseInfoField', {
    attrs: {
      "label": "Antall overnattinger",
      "value": (_vm$dietFromExpense10 = _vm.dietFromExpense) === null || _vm$dietFromExpense10 === void 0 ? void 0 : _vm$dietFromExpense10.quantity
    }
  }) : _vm._e(), _vm.dietFromExpense.providedBreakfastQuantity ? _c('BaseInfoField', {
    attrs: {
      "label": "Antall frokoster",
      "value": _vm.dietFromExpense.providedBreakfastQuantity
    }
  }) : _vm._e(), _vm.dietFromExpense.providedLunchQuantity ? _c('BaseInfoField', {
    attrs: {
      "label": "Antall lunsjer",
      "value": _vm.dietFromExpense.providedLunchQuantity
    }
  }) : _vm._e(), _vm.dietFromExpense.providedDinnerQuantity ? _c('BaseInfoField', {
    attrs: {
      "label": "Antall middager",
      "value": _vm.dietFromExpense.providedDinnerQuantity
    }
  }) : _vm._e()], 1) : _vm._e()], 2) : _vm._e()], 1), _c('v-row', [(_vm$mainFormValues5 = _vm.mainFormValues) !== null && _vm$mainFormValues5 !== void 0 && _vm$mainFormValues5.stipend && _vm.hasScholarship ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('h2', [_vm._v("Stipend")]), _c('v-card', {
    staticClass: "pa-4 mb-4",
    attrs: {
      "elevation": "2",
      "outlined": ""
    }
  }, [(_vm$mainFormValues6 = _vm.mainFormValues) !== null && _vm$mainFormValues6 !== void 0 && (_vm$mainFormValues6$s = _vm$mainFormValues6.stipend) !== null && _vm$mainFormValues6$s !== void 0 && _vm$mainFormValues6$s.fromDate ? _c('BaseInfoField', {
    attrs: {
      "label": "Dato fra",
      "value": _vm.formatLocalizedDate((_vm$mainFormValues7 = _vm.mainFormValues) === null || _vm$mainFormValues7 === void 0 ? void 0 : (_vm$mainFormValues7$s = _vm$mainFormValues7.stipend) === null || _vm$mainFormValues7$s === void 0 ? void 0 : _vm$mainFormValues7$s.fromDate)
    }
  }) : _vm._e(), (_vm$mainFormValues8 = _vm.mainFormValues) !== null && _vm$mainFormValues8 !== void 0 && (_vm$mainFormValues8$s = _vm$mainFormValues8.stipend) !== null && _vm$mainFormValues8$s !== void 0 && _vm$mainFormValues8$s.toDate ? _c('BaseInfoField', {
    attrs: {
      "label": "Dato til",
      "value": _vm.formatLocalizedDate((_vm$mainFormValues9 = _vm.mainFormValues) === null || _vm$mainFormValues9 === void 0 ? void 0 : (_vm$mainFormValues9$s = _vm$mainFormValues9.stipend) === null || _vm$mainFormValues9$s === void 0 ? void 0 : _vm$mainFormValues9$s.toDate)
    }
  }) : _vm._e(), (_vm$mainFormValues10 = _vm.mainFormValues) !== null && _vm$mainFormValues10 !== void 0 && (_vm$mainFormValues10$ = _vm$mainFormValues10.stipend) !== null && _vm$mainFormValues10$ !== void 0 && _vm$mainFormValues10$.hours ? _c('BaseInfoField', {
    attrs: {
      "label": "Antall timer tapt arbeidsfortjeneste",
      "value": (_vm$mainFormValues11 = _vm.mainFormValues) === null || _vm$mainFormValues11 === void 0 ? void 0 : _vm$mainFormValues11.stipend.hours
    }
  }) : _vm._e(), (_vm$mainFormValues12 = _vm.mainFormValues) !== null && _vm$mainFormValues12 !== void 0 && (_vm$mainFormValues12$ = _vm$mainFormValues12.stipend) !== null && _vm$mainFormValues12$ !== void 0 && _vm$mainFormValues12$.description ? _c('BaseInfoField', {
    attrs: {
      "label": "Kommentar",
      "value": (_vm$mainFormValues13 = _vm.mainFormValues) === null || _vm$mainFormValues13 === void 0 ? void 0 : _vm$mainFormValues13.stipend.description
    }
  }) : _vm._e(), _vm._l(_vm.getAttachmentIds((_vm$mainFormValues14 = _vm.mainFormValues) === null || _vm$mainFormValues14 === void 0 ? void 0 : _vm$mainFormValues14.stipend), function (attachmentId) {
    return _c('div', {
      key: attachmentId
    }, [_c('CourseRefundDisplayFile', {
      attrs: {
        "fileId": attachmentId
      }
    })], 1);
  })], 2)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }