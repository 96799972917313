var render = function () {
  var _vm$mainFormValues, _vm$mainFormValues$se, _vm$mainFormValues2, _vm$mainFormValues3, _vm$mainFormValues3$s, _vm$mainFormValues4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading ? _c('BaseLayout', {
    attrs: {
      "displayBreadCrumbs": true
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        var _vm$course, _vm$course2;
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateBack
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(_vm._s(("Refusjon (" + ((_vm$course = _vm.course) === null || _vm$course === void 0 ? void 0 : _vm$course.id) + " - " + ((_vm$course2 = _vm.course) === null || _vm$course2 === void 0 ? void 0 : _vm$course2.externalTitle) + ")")))];
      },
      proxy: true
    }], null, false, 999923503)
  }, [_c('v-stepper', {
    model: {
      value: _vm.currentStep,
      callback: function ($$v) {
        _vm.currentStep = $$v;
      },
      expression: "currentStep"
    }
  }, [_vm.canEdit ? _c('v-stepper-header', [_c('v-stepper-step', {
    attrs: {
      "step": "1"
    }
  }, [_vm._v(" Generell info")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": "2"
    }
  }, [_vm._v(" Reise til kursstedet")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": "3"
    }
  }, [_vm._v(" Reise fra kursstedet")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": "4"
    }
  }, [_vm._v(" Stipend")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": "5"
    }
  }, [_vm._v(" Sammendrag")])], 1) : _vm._e(), _c('v-stepper-items', [_c('v-stepper-content', {
    attrs: {
      "step": "1"
    }
  }, [_vm.isMobile ? _c('h2', [_vm._v("Generell info")]) : _vm._e(), _c('CourseRefundGeneral', {
    attrs: {
      "isFormDisabled": _vm.isFormsDisabled,
      "courseId": _vm.courseId,
      "selectedSteps": _vm.getSelectedSteps(),
      "bankAccountNo": _vm.mainFormValues.bankAccountNo || '',
      "description": _vm.mainFormValues.description || ''
    },
    on: {
      "submit": _vm.updateFormState
    }
  })], 1), _c('v-stepper-content', {
    attrs: {
      "step": "2"
    }
  }, [_vm.isMobile ? _c('h2', [_vm._v("Reise til kursstedet")]) : _vm._e(), _c('CourseRefundToAndFrom', {
    attrs: {
      "isFormDisabled": _vm.isFormsDisabled,
      "showFoodSection": ((_vm$mainFormValues = _vm.mainFormValues) === null || _vm$mainFormValues === void 0 ? void 0 : (_vm$mainFormValues$se = _vm$mainFormValues.selectedSteps) === null || _vm$mainFormValues$se === void 0 ? void 0 : _vm$mainFormValues$se.indexOf(_vm.RefundSteps.Food)) !== -1,
      "editValues": (_vm$mainFormValues2 = _vm.mainFormValues) === null || _vm$mainFormValues2 === void 0 ? void 0 : _vm$mainFormValues2.expenseLinesTo,
      "dietExpense": _vm.dietToExpense,
      "costTypes": _vm.costTypes,
      "defaultDates": _vm.defaultDates
    },
    on: {
      "submit": _vm.updateFormState,
      "previous-step": function ($event) {
        return _vm.switchToStep(_vm.StepperDirections.Previous);
      },
      "dietChanged": function (e) {
        return _vm.dietToExpense = e;
      }
    }
  })], 1), _c('v-stepper-content', {
    attrs: {
      "step": "3"
    }
  }, [_vm.isMobile ? _c('h2', [_vm._v("Reise fra kursstedet")]) : _vm._e(), _c('CourseRefundToAndFrom', {
    attrs: {
      "isInboundTravel": false,
      "isFormDisabled": _vm.isFormsDisabled,
      "showFoodSection": ((_vm$mainFormValues3 = _vm.mainFormValues) === null || _vm$mainFormValues3 === void 0 ? void 0 : (_vm$mainFormValues3$s = _vm$mainFormValues3.selectedSteps) === null || _vm$mainFormValues3$s === void 0 ? void 0 : _vm$mainFormValues3$s.indexOf(_vm.RefundSteps.Food)) !== -1,
      "editValues": (_vm$mainFormValues4 = _vm.mainFormValues) === null || _vm$mainFormValues4 === void 0 ? void 0 : _vm$mainFormValues4.expenseLinesFrom,
      "dietExpense": _vm.dietFromExpense,
      "costTypes": _vm.costTypes,
      "defaultDates": _vm.defaultDates
    },
    on: {
      "submit": _vm.updateFormState,
      "previous-step": function ($event) {
        return _vm.switchToStep(_vm.StepperDirections.Previous);
      },
      "dietChanged": function (e) {
        return _vm.dietFromExpense = e;
      }
    }
  })], 1), _c('v-stepper-content', {
    attrs: {
      "step": "4"
    }
  }, [_vm.isMobile ? _c('h2', [_vm._v("Stipend")]) : _vm._e(), _c('CourseRefundScholarship', {
    attrs: {
      "stipend": _vm.mainFormValues.stipend,
      "costTypes": _vm.costTypes,
      "defaultDates": _vm.defaultDates
    },
    on: {
      "submit": _vm.updateFormState,
      "previous-step": function ($event) {
        return _vm.switchToStep(_vm.StepperDirections.Previous);
      }
    }
  })], 1), _c('v-stepper-content', {
    attrs: {
      "step": "5"
    },
    on: {
      "previous-step": function ($event) {
        return _vm.switchToStep(_vm.StepperDirections.Previous);
      }
    }
  }, [_vm.mainFormValues ? _c('CourseRefundReceipt', {
    attrs: {
      "refundStatus": _vm.mainFormValues.status,
      "mainFormValues": _vm.mainFormValues,
      "dietToExpense": _vm.dietToExpense,
      "dietFromExpense": _vm.dietFromExpense,
      "costTypes": _vm.costTypes,
      "totalRefundAmount": _vm.totalRefundAmount
    }
  }) : _vm._e(), _vm.canEdit ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.switchToStep(_vm.StepperDirections.Previous);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-left")]), _vm._v(" Tilbake ")], 1) : _vm._e(), _vm.canEdit ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-content-save")]), _vm._v(" Lagre utkast ")], 1) : _vm._e(), _vm.canEdit && _vm.canSend ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.saveAndSendToApproval();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-send")]), _vm._v(" Lagre og send til godkjenning ")], 1) : _vm._e()], 1)], 1)], 1), _vm.isRejected ? _c('div', {
    staticStyle: {
      "margin": "20px 0"
    }
  }, [_c('span', {
    staticStyle: {
      "margin-left": "20px"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "red"
    }
  }, [_vm._v("mdi-flag")]), _vm._v(" NB! Dette refusjonskravet har ikke blitt godkjent. Se under for tilbakemelding. ")], 1), _c('div', {
    staticClass: "chat-log__container"
  }, [_c('div', {
    staticClass: "chat__bubble"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.rejectionComment) + " ")])])])]) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }