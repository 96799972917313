
import { validateArrayNotEmpty } from "@/shared/helpers/validationHelpers";
import { defineComponent, onMounted, PropType, ref, watch } from "@vue/composition-api";
import { api, binaryApi } from "@/api/api";
import { useRoute } from "@/shared/useHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";

interface ExtendedFile extends File {
  id?: number;
  isLoading?: boolean;
}

export default defineComponent({
  name: "CourseRefundFileUpload",
  props: {
    label: {
      type: String,
      required: false,
      default: "Kvittering",
    },
    helpText: {
      type: String,
      required: false,
    },
    disableValidation: {
      type: Boolean,
      required: false,
      default: false,
    },
    attachmentIds: {
      type: Array as PropType<number[]>,
      required: false,
      default: [],
    },
  },
  emits: ["input:change"],
  setup({ attachmentIds }, { emit }) {
    const route = useRoute();
    const courseId = +route.params.id;

    const files = ref<ExtendedFile[]>([]);
    const fileIdsOfUploadedFiles = ref<number[]>(attachmentIds);
    const isUploadingFiles = ref<boolean>(false);

    onMounted(() => {
      globalLoadingWrapper({ blocking: true }, async () => {
        files.value = await Promise.all(
          fileIdsOfUploadedFiles.value.map(async (fileId) => {
            return await getFile(fileId);
          })
        );
      });
    });

    watch(
      () => files,
      () => {
        emitFormValues();
      },
      {
        deep: true,
      }
    );

    watch(fileIdsOfUploadedFiles, () => {
      emitFormValues();
    });

    const emitFormValues = () => {
      emit("input:change", fileIdsOfUploadedFiles.value);
    };

    const getFile = async (id: number) => {
      // I suspect that we don't actually get back a file object, but a blob with api.file.download
      const fileBits = (await binaryApi.file.downloadFileAsync(id, { format: "blob" })).data;
      const file: ExtendedFile = new File([fileBits], "fil-" + id);
      file.id = id;
      file.isLoading = false;
      return file;
    };

    const uploadReceipts = async () => {
      isUploadingFiles.value = true;
      for (const file of files.value) {
        const indexOfFileToBeUploaded = files.value.findIndex((uploadedFile) => uploadedFile.name === file.name);
        if (indexOfFileToBeUploaded !== -1 && !("id" in file)) {
          files.value[indexOfFileToBeUploaded].isLoading = true;
          await api.travelAndExpense
            .createCourseParticipantTravelAndExpenseFile({ CourseId: courseId, File: file })
            .then((res) => {
              files.value[indexOfFileToBeUploaded].id = res.data.id;
              files.value[indexOfFileToBeUploaded].isLoading = false;
              fileIdsOfUploadedFiles.value = [...fileIdsOfUploadedFiles.value, res.data.id];
              files.value = [...files.value];
            })
            .catch(() => {
              files.value.splice(indexOfFileToBeUploaded, 1);
            });
        }
      }
      isUploadingFiles.value = false;
    };

    // In a later release we will have a proper api to delete files, now the ref is only removed
    const deleteFile = (index: number) => {
      const fileId = files.value[index].id;
      if (fileId) {
        fileIdsOfUploadedFiles.value = fileIdsOfUploadedFiles.value.filter((id) => id !== fileId);
        files.value.splice(index, 1);
      }
    };

    return {
      files,
      isUploadingFiles,
      deleteFile,
      uploadReceipts,
      validateArrayNotEmpty,
    };
  },
});
