var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-form', {
    ref: "newRefundClaimForm",
    attrs: {
      "disabled": _vm.isFormDisabled
    }
  }, [_c('v-col', {
    staticClass: "d-flex justify-start"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('div', {
    staticClass: "d-flex justify-start",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.validateAccountNumber,
      "data-cy": "expenseAccountNumberInput",
      "label": "Bankkontonummer *"
    },
    model: {
      value: _vm.formValues.bankAccountNo,
      callback: function ($$v) {
        _vm.$set(_vm.formValues, "bankAccountNo", $$v);
      },
      expression: "formValues.bankAccountNo"
    }
  }), _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-icon', _vm._g(_vm._b({}, 'v-icon', attrs, false), on), [_vm._v("mdi-help-circle")])];
      }
    }])
  }, [_c('span', [_vm._v("Utbetalinger vil alltid gjøres til sist oppgitte bankkontonummer")])])], 1)])]), !_vm.selectedSteps ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('label', [_vm._v("Hva skal du ha refusjon for?")]), _c('v-checkbox', {
    attrs: {
      "rules": [_vm.validateCheckboxes],
      "label": "Stipend"
    },
    model: {
      value: _vm.scholarship,
      callback: function ($$v) {
        _vm.scholarship = $$v;
      },
      expression: "scholarship"
    }
  }), _c('v-checkbox', {
    attrs: {
      "rules": [_vm.validateCheckboxes],
      "label": "Reise"
    },
    model: {
      value: _vm.travel,
      callback: function ($$v) {
        _vm.travel = $$v;
      },
      expression: "travel"
    }
  }), _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('div', {
          staticClass: "d-inline-flex align-baseline"
        }, [_c('v-checkbox', {
          staticClass: "pr-2",
          attrs: {
            "rules": [_vm.validateCheckboxes],
            "disabled": !_vm.travel,
            "label": "Diett"
          },
          model: {
            value: _vm.food,
            callback: function ($$v) {
              _vm.food = $$v;
            },
            expression: "food"
          }
        }), !_vm.travel ? _c('v-icon', _vm._g(_vm._b({}, 'v-icon', attrs, false), on), [_vm._v("mdi-help-circle")]) : _vm._e()], 1)];
      }
    }], null, false, 2312666293)
  }, [_c('span', [_vm._v("Man kan ikke velge diett hvis man ikke har reist")])]), _c('v-textarea', {
    attrs: {
      "name": "Kommentar",
      "label": "Kommentar",
      "filled": ""
    },
    model: {
      value: _vm.formValues.description,
      callback: function ($$v) {
        _vm.$set(_vm.formValues, "description", $$v);
      },
      expression: "formValues.description"
    }
  })], 1) : _vm._e()], 1), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function () {
        return _vm.submit();
      }
    }
  }, [_vm._v(" Neste "), _c('v-icon', [_vm._v("mdi-arrow-right")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }