
import { ApiGetMinSideCourseDto } from "@/api/generated/Api";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import BaseTimePicker from "@/components/shared/time/BaseTimePicker.vue";
import { createDateTime, getTimeDifferenceInMinutes } from "@/shared/helpers/dateHelpers";
import { validateNotEmpty, validateTimeInput } from "@/shared/helpers/validationHelpers";
import { Ref, defineComponent, onMounted, ref, watch } from "@vue/composition-api";
import { format } from "date-fns";

const parseDateTimeString = (dateString: string) => {
  const date = new Date(dateString);

  return { time: `${format(date, "HH")}:${format(date, "mm")}`, date: dateString };
};

export default defineComponent({
  name: "CourseRefundDurationInput",
  components: { BaseDatePicker, BaseTimePicker },
  props: {
    arrival: {
      type: String,
      required: false,
    },
    departure: {
      type: String,
      required: false,
    },
    isInboundTravel: {
      default: true,
      type: Boolean,
    },
    keepDiet: {
      default: true,
      type: Boolean,
    },
    defaultDates: {
      type: Object,
      required: true,
    },
  },
  emits: ["input", "duration"],
  setup({ arrival, departure, defaultDates }, { emit }) {
    const timeDifference = ref<number>(0);
    const defaultArrivalDate = ref();
    const defaultDepartureDate = ref();
    const course = ref<ApiGetMinSideCourseDto>();
    const dateValues: Ref<any> = ref({
      departure: {
        time: undefined,
        date: undefined,
      },
      arrival: {
        time: undefined,
        date: undefined,
      },
    });

    const setDefaultDates = async () => {
      if (arrival || departure) {
        if (departure) {
          defaultDepartureDate.value = parseDateTimeString(departure).date;
        }
        if (arrival) {
          defaultArrivalDate.value = parseDateTimeString(arrival).date;
        }
        return;
      }

      defaultArrivalDate.value = defaultDates.arrival;
      defaultDepartureDate.value = defaultDates.departure;
    };

    onMounted(async () => {
      await setDefaultDates();
    });

    watch(dateValues.value, () => {
      if (dateValues.value.departure.time) {
        emitFormValues("departure", "time", dateValues.value.departure.time);
      }

      if (dateValues.value.arrival.time) {
        emitFormValues("arrival", "time", dateValues.value.arrival.time);
      }
    });

    const emitFormValues = (toFrom: string, value: string, inputValue: string) => {
      dateValues.value[toFrom][value] = inputValue;
      emit("input", {
        departure: combineDateAndTime(dateValues.value.departure.date, dateValues.value.departure.time),
        arrival: combineDateAndTime(dateValues.value.arrival.date, dateValues.value.arrival.time),
      });
      if (
        dateValues.value.departure.date &&
        dateValues.value.departure.time &&
        dateValues.value.arrival.date &&
        dateValues.value.arrival.time
      ) {
        timeDifference.value =
          getTimeDifferenceInMinutes(
            createDateTime(dateValues.value.departure.date, dateValues.value.departure.time),
            createDateTime(dateValues.value.arrival.date, dateValues.value.arrival.time)
          ) || 0;
        emit("duration", timeDifference.value);
      }
    };

    const combineDateAndTime = (date: string, time: string) =>
      date && time ? createDateTime(date, time).toISOString() : "";

    return {
      validateNotEmpty,
      emitFormValues,
      timeDifference,
      dateValues,
      validateTimeInput,
      course,
      defaultArrivalDate,
      defaultDepartureDate,
    };
  },
});
