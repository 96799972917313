
import { computed, ComputedRef, defineComponent, PropType, ref, Ref, watch } from "@vue/composition-api";
import { ApiCourseParticipantTravelAndExpenseAddressDto, ApiSsbCountryDto } from "@/api/generated/Api";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";

export default defineComponent({
  name: "BaseAddressFields",
  props: {
    address: {
      type: Object as PropType<ApiCourseParticipantTravelAndExpenseAddressDto>,
      required: false,
    },
  },
  emits: ["input"],
  setup({ address }, { emit }) {
    const store = useStore<StoreState>();
    const inputValues: Ref<ApiCourseParticipantTravelAndExpenseAddressDto> = ref({
      addressLine: "",
      postalCode: "",
      postalArea: "",
      country: "000",
      ...address,
    });

    const countries: ComputedRef<ApiSsbCountryDto[]> = computed(() => store.getters["global/getCountries"]);
    if (!countries.value?.length) {
      store.dispatch("global/fetchCountries");
    }

    watch(
      inputValues,
      () => {
        emit("input", inputValues.value);
      },
      {
        deep: true,
      }
    );

    return { inputValues, countries, validateNotEmpty };
  },
});
