var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    ref: "menu",
    attrs: {
      "close-on-content-click": false,
      "nudge-right": 40,
      "return-value": _vm.time,
      "transition": "scale-transition",
      "offset-y": "",
      "max-width": "290px",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        _vm.time = $event;
      },
      "update:return-value": function ($event) {
        _vm.time = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": _vm.label,
            "prepend-icon": "mdi-clock-time-four-outline",
            "readonly": "",
            "rules": [_vm.validateNotEmpty]
          },
          model: {
            value: _vm.time,
            callback: function ($$v) {
              _vm.time = $$v;
            },
            expression: "time"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.showMenu,
      callback: function ($$v) {
        _vm.showMenu = $$v;
      },
      expression: "showMenu"
    }
  }, [_vm.showMenu ? _c('v-time-picker', {
    attrs: {
      "format": "24hr",
      "full-width": ""
    },
    on: {
      "click:minute": function ($event) {
        return _vm.$refs.menu.save(_vm.time);
      }
    },
    model: {
      value: _vm.time,
      callback: function ($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }