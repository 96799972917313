var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.display ? _c('fieldset', {
    staticClass: "pb-4"
  }, [_c('h2', [_vm._v("Diett")]), _c('span', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('v-switch', {
    model: {
      value: _vm.keepDiet,
      callback: function ($$v) {
        _vm.keepDiet = $$v;
      },
      expression: "keepDiet"
    }
  }), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v(" Huk av her om du ikke ønsker å føre refusjon på diett " + _vm._s(_vm.isInboundTravel ? "til" : "fra") + " kurset ")])], 1), [_c('CourseRefundDurationInput', {
    attrs: {
      "isInboundTravel": _vm.isInboundTravel,
      "departure": _vm.departure,
      "arrival": _vm.arrival,
      "keepDiet": _vm.keepDiet,
      "defaultDates": _vm.defaultDates
    },
    on: {
      "input": _vm.updateFormValues,
      "duration": function (e) {
        return _vm.travelDuration = e;
      }
    }
  }), _vm.keepDiet ? _c('p', {
    staticClass: "mt-3"
  }, [_vm._v(" Hvor lang tid tok reisen " + _vm._s(_vm.isInboundTravel ? "til" : "fra") + " kurset? "), _c('strong', [_vm._v(_vm._s(_vm.travelDurationDisplay || "0 timer og 0 minutter"))])]) : _vm._e()], _vm.travelDuration >= 360 && _vm.keepDiet ? [_c('v-divider', {
    staticClass: "mt-4"
  }), _c('v-select', {
    attrs: {
      "items": _vm.costTypesDiet,
      "readonly": "",
      "item-text": "name",
      "item-value": "id",
      "label": "Din valgte diettsats",
      "rules": [_vm.validateNotEmpty]
    },
    model: {
      value: _vm.formValues.costId,
      callback: function ($$v) {
        _vm.$set(_vm.formValues, "costId", $$v);
      },
      expression: "formValues.costId"
    }
  }), _vm.travelDuration > 720 ? _c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Hvor mange overnattinger hadde du på reisen fra kurset? Overnatting som ikke er knyttet til selve reisen skal ikke legges inn her. Hvis du ikke overnattet på reisen legg inn 0.",
      "rules": [_vm.validateNotEmpty]
    },
    model: {
      value: _vm.formValues.quantity,
      callback: function ($$v) {
        _vm.$set(_vm.formValues, "quantity", $$v);
      },
      expression: "formValues.quantity"
    }
  }) : _vm._e(), _c('label', [_vm._v("Fikk du dekket noe måltider på reisen?")]), _c('v-text-field', {
    attrs: {
      "rules": [_vm.validateMealsAllowed],
      "label": "Middag - antall",
      "type": "number",
      "min": 0
    },
    model: {
      value: _vm.formValues.providedDinnerQuantity,
      callback: function ($$v) {
        _vm.$set(_vm.formValues, "providedDinnerQuantity", $$v);
      },
      expression: "formValues.providedDinnerQuantity"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.validateMealsAllowed],
      "label": "Lunsj - antall",
      "type": "number",
      "min": 0
    },
    model: {
      value: _vm.formValues.providedLunchQuantity,
      callback: function ($$v) {
        _vm.$set(_vm.formValues, "providedLunchQuantity", $$v);
      },
      expression: "formValues.providedLunchQuantity"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.validateMealsAllowed],
      "label": "Frokost - antall",
      "type": "number",
      "min": 0
    },
    model: {
      value: _vm.formValues.providedBreakfastQuantity,
      callback: function ($$v) {
        _vm.$set(_vm.formValues, "providedBreakfastQuantity", $$v);
      },
      expression: "formValues.providedBreakfastQuantity"
    }
  })] : _vm._e(), !_vm.keepDiet ? [_c('span', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "green"
    }
  }, [_vm._v("mdi-flag")]), _c('p', {
    staticClass: "mt-4"
  }, [_vm._v("Du har valgt å ikke føre reise diett " + _vm._s(_vm.isInboundTravel ? "til" : "fra") + " kurset")])], 1)] : _vm._e(), _vm.travelDuration < 360 ? [_c('span', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "red"
    }
  }, [_vm._v("mdi-flag")]), _c('p', {
    staticClass: "mt-4"
  }, [_vm._v("Reisen må være over 6t for at diett skal bli dekket.")])], 1)] : _vm._e()], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }