
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { computed, defineComponent, PropType, Ref, ref, watch } from "@vue/composition-api";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import CourseSectionRefundAddressInput from "@/components/courses/section/CourseSectionRefund/RefundFormComponents/CourseRefundAddressInput.vue";
import CourseRefundFileUpload from "@/components/courses/section/CourseSectionRefund/RefundFormComponents/CourseRefundFileUpload.vue";
import { ApiExpenseLineDto, ApiGetCostDto, ApiGetExpenseLineDto } from "@/api/generated/Api";
import { TravelAndExpenseType } from "@/shared/enums/courseRefund.enum";

export default defineComponent({
  name: "CourseRefundAlternativeExpense",
  components: {
    BaseDatePicker,
    CourseSectionRefundAddressInput,
    CourseRefundFileUpload,
  },
  props: {
    expense: {
      type: Object as PropType<ApiGetExpenseLineDto>,
      required: true,
    },
    cost: {
      type: Object as PropType<ApiGetCostDto>,
      required: false,
    },
    costIdsNotToBeDisplayed: {
      type: Array as PropType<number[]>,
      required: false,
    },
    costTypesVehicle: {
      type: Array as PropType<ApiGetCostDto[]>,
      required: false,
    },
    textAreaLabel: {
      type: String,
      required: false,
      default: "Kommentar",
    },

    passengers: {
      type: Array as PropType<ApiExpenseLineDto[]>,
      required: false,
    },
    displayAmountField: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["input:change", "addPassenger", "removePassenger", "updatePassengerList"],
  setup({ expense, costIdsNotToBeDisplayed, costTypesVehicle, passengers }, { emit }) {
    const formValues: Ref<ApiExpenseLineDto> = ref({
      ...expense,
      attachmentIds: expense.attachments?.map((x) => x.id) ?? [],
    });
    const passengersExpense = ref<ApiExpenseLineDto[]>(passengers ?? []);

    const amountOfCoTravelers = ref<number>(passengers?.length ?? 0);

    const shouldDisplayCost = computed<boolean>(() =>
      costIdsNotToBeDisplayed ? !costIdsNotToBeDisplayed.includes(expense.costId) : true
    );

    const isVehicleCost = computed<boolean>(() =>
      costTypesVehicle ? !!costTypesVehicle.find((vehicle) => vehicle.id === expense.costId) : false
    );

    watch(passengersExpense.value, () => emit("updatePassengerList", passengersExpense.value));

    watch(
      () => amountOfCoTravelers.value,
      (newAmount, oldAmount) => {
        if (newAmount > oldAmount) {
          emit("addPassenger");
        } else {
          emit("removePassenger");
        }
      }
    );

    watch(
      () => formValues.value,
      () => {
        emitFormValues();
      },
      {
        deep: true,
      }
    );

    const updateFormValues = (val: any) => {
      formValues.value = { ...formValues.value, ...val };
      emitFormValues();
    };

    const emitFormValues = () => {
      emit("input:change", { ...formValues.value });
    };

    const updateAttachments = (value: number[]) => {
      formValues.value.attachmentIds = value;
    };

    return {
      formValues,
      amountOfCoTravelers,
      passengersExpense,
      shouldDisplayCost,
      isVehicleCost,
      validateNotEmpty,
      updateAttachments,
      updateFormValues,
      TravelAndExpenseType,
    };
  },
});
