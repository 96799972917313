
import { api } from "@/api/api";
import {
  ApiCreateCourseParticipantTravelAndExpenseDto,
  ApiExpenseLineDto,
  ApiGetCostDto,
  ApiGetCourseParticipantTravelAndExpenseDto,
  ApiGetMinSideCourseDto,
} from "@/api/generated/Api";
import CourseRefundGeneral from "@/components/courses/section/CourseSectionRefund/RefundFormSections/CourseRefundGeneral.vue";
import CourseRefundReceipt from "@/components/courses/section/CourseSectionRefund/RefundFormSections/CourseRefundReceipt.vue";
import CourseRefundScholarship from "@/components/courses/section/CourseSectionRefund/RefundFormSections/CourseRefundScholarship.vue";
import CourseRefundToAndFrom from "@/components/courses/section/CourseSectionRefund/RefundFormSections/CourseRefundToAndFrom.vue";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import BaseLayout from "@/components/shared/layout/BaseLayout.vue";
import {
  EligibleCourseRefundStatus,
  EligibleUserRefundStatus,
  RefundSteps,
  TravelAndExpenseStatus,
} from "@/shared/enums/courseRefund.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { dietTypes, filterCostTypes } from "@/shared/helpers/courseRefundHelpers";
import { getSingleCourseStorageData } from "@/shared/helpers/courseStorageHelper";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { isMobile } from "@/shared/helpers/displayHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, Ref, ref } from "@vue/composition-api";

export interface ExtendedApiGetCourseParticipantTravelAndExpenseDto
  extends ApiCreateCourseParticipantTravelAndExpenseDto {
  selectedSteps?: RefundSteps[];
}

enum StepperDirections {
  Next = "next",
  Previous = "previous",
}

export default defineComponent({
  name: "CourseSectionNewRefundClaim",
  components: {
    CourseRefundGeneral,
    CourseRefundToAndFrom,
    BaseDatePicker,
    BaseLayout,
    CourseRefundReceipt,
    CourseRefundScholarship,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore<StoreState>();
    const courseId = +route.params.id;
    const refundClaimId = +route.params.refundClaimId;
    const currentStep = ref<number>(1);
    const allSteps = ref(Object.values(RefundSteps));
    const showReceiptPage = ref<boolean>(false);
    const loading = ref(true);
    const costTypes = ref<ApiGetCostDto[]>([]);
    const dietToExpense = ref<ApiExpenseLineDto>();
    const dietFromExpense = ref<ApiExpenseLineDto>();
    const canEdit = ref<boolean>(true);
    const newRefundClaimId = ref<number>();
    const totalRefundAmount = ref<number>(0);
    const rejectionComment = ref<string | null | undefined>("");
    const isRejected = ref(false);
    const defaultDates = ref({});
    const course = ref<ApiGetMinSideCourseDto>();
    const userStatus = window.sessionStorage.getItem("participantStatus");
    const courseStatus = ref<string>("");
    const canSend = computed(
      () =>
        Object.values(EligibleUserRefundStatus).includes(userStatus?.toLowerCase() as EligibleUserRefundStatus) &&
        Object.values(EligibleCourseRefundStatus).includes(
          courseStatus.value.toLowerCase() as EligibleCourseRefundStatus
        )
    );

    const getCourseSetDefaultDates = async () => {
      const response = (await api.minside.getCourseByIdAsync(courseId)).data;
      course.value = response;
      courseStatus.value = course.value.status || "";

      defaultDates.value = {
        arrival: response.startDate,
        departure: response.endDate,
      };
    };

    onMounted(async () => {
      await getCourseSetDefaultDates();
      await getCostTypes();
      if (refundClaimId) {
        await loadRefundClaim();
      } else {
        loading.value = false;
      }
    });

    const isFormsDisabled = ref<boolean>(false);

    const mainFormValues = ref<ExtendedApiGetCourseParticipantTravelAndExpenseDto>({
      courseId,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString(),
      expenseLinesFrom: [],
      expenseLinesTo: [],
    });

    const updateFormState = (value: any) => {
      mainFormValues.value = { ...mainFormValues.value, ...value };

      if (mainFormValues.value.expenseLinesFrom?.length) {
        mainFormValues.value.expenseLinesFrom = mainFormValues.value?.expenseLinesFrom.filter(
          (obj, index) =>
            mainFormValues.value.expenseLinesFrom?.findIndex((item) => item.costId === obj.costId) === index
        );
      }

      if (mainFormValues.value.expenseLinesTo?.length) {
        mainFormValues.value.expenseLinesTo = mainFormValues.value?.expenseLinesTo.filter(
          (obj, index) => mainFormValues.value.expenseLinesTo?.findIndex((item) => item.costId === obj.costId) === index
        );
      }

      switchToStep();
    };

    const switchToStep = (direction = StepperDirections.Next) => {
      const currentStepValue = allSteps.value[currentStep.value - 1];

      if (mainFormValues.value.selectedSteps) {
        const indexOfCurrentStepInSelectedSteps: number =
          mainFormValues.value?.selectedSteps.indexOf(currentStepValue) ?? -1;
        if (indexOfCurrentStepInSelectedSteps !== -1) {
          let nextStepInSelectedSteps =
            mainFormValues.value?.selectedSteps[
              direction === StepperDirections.Next
                ? indexOfCurrentStepInSelectedSteps + 1
                : indexOfCurrentStepInSelectedSteps - 1
            ];
          if (nextStepInSelectedSteps === RefundSteps.Food) {
            nextStepInSelectedSteps =
              mainFormValues.value?.selectedSteps[
                direction === StepperDirections.Next
                  ? indexOfCurrentStepInSelectedSteps + 2
                  : indexOfCurrentStepInSelectedSteps - 2
              ];
          }
          if (nextStepInSelectedSteps) {
            const indexOfNextStepInAllSteps = allSteps.value.indexOf(nextStepInSelectedSteps);
            currentStep.value = indexOfNextStepInAllSteps + 1;
          }
        }
      }
    };

    const saveForm = async () => {
      const request = deepCloneObject(mainFormValues.value) as ApiGetCourseParticipantTravelAndExpenseDto; // TODO the typing crazyness should be fixed. *sigh*

      if (!mainFormValues.value.selectedSteps?.includes(RefundSteps.Scholarship)) {
        request.stipend = undefined;
      }

      if (!mainFormValues.value.selectedSteps?.includes(RefundSteps.TravelFrom || RefundSteps.TravelTo)) {
        request.expenseLinesFrom = [];
        request.expenseLinesTo = [];
      }

      // Add the diet expenses to the expenseLinesTo/expenseLinesFrom before sending request
      if (dietToExpense.value && dietToExpense.value.costId !== 0) {
        request.expenseLinesTo?.push({
          ...dietToExpense.value,
          totalAmount: 0,
        });
      }

      if (dietFromExpense.value && dietFromExpense.value.costId !== 0) {
        request.expenseLinesFrom?.push({
          ...dietFromExpense.value,
          totalAmount: 0,
        });
      }

      if (refundClaimId || newRefundClaimId.value) {
        const id = refundClaimId || newRefundClaimId.value;

        if (!id) {
          return;
        }

        // If we are updating, the structure is different
        request.expenseLinesFrom?.forEach((expense) => {
          delete expense.attachments;
        });
        request.expenseLinesTo?.forEach((expense) => {
          delete expense.attachments;
        });
        await api.travelAndExpense.updateCourseParticipantTravelAndExpense(id, request);
      } else {
        newRefundClaimId.value = (await api.travelAndExpense.createCourseParticipantTravelAndExpense(request)).data.id;
      }
    };

    const submitForm = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        await saveForm().then(() => {
          getTotalAmount();
          if (refundClaimId) {
            openNotification(store, NotificationItemType.Success, "Refusjon oppdatert", 5000);
          } else {
            openNotification(store, NotificationItemType.Success, "Refusjon opprettet", 5000);
          }
        });
      });
    };

    const saveAndSendToApproval = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        await saveForm().then(async () => {
          const travelAndExpenseId = refundClaimId || newRefundClaimId.value;

          if (travelAndExpenseId) {
            await api.travelAndExpense.sendCourseParticipantTravelAndExpenseForApproval(travelAndExpenseId).then(() => {
              openNotification(store, NotificationItemType.Success, "Refusjon er sendt til godkjenning", 5000);
            });
          }
        });
      });

      rerouteToCoursePage();
    };

    const rerouteToCoursePage = () => {
      // Get course data (userStatus and appliedViaForm) from session storage as they are used in the routing
      // See comment about routing situation in courseRouteList.ts
      const singleCourseData = getSingleCourseStorageData(courseId);

      router.push({
        name: "singleCourse",
        params: {
          id: courseId.toString(),
          userStatus: singleCourseData.userStatus,
          appliedViaForm: singleCourseData.appliedViaForm ? singleCourseData.appliedViaForm.toString() : "false",
          tab: "refundForm",
        },
      });
    };

    const getTotalAmount = async (fetchedRefundClaim?: ApiGetCourseParticipantTravelAndExpenseDto) => {
      if (!refundClaimId && !newRefundClaimId.value) {
        return;
      }

      const id = refundClaimId || newRefundClaimId.value;

      if (!id) {
        return;
      }

      const { expenseLinesFrom, expenseLinesTo, stipend } =
        fetchedRefundClaim || (await api.travelAndExpense.getCourseParticipantTravelAndExpense(id)).data;

      if (expenseLinesFrom) {
        totalRefundAmount.value += expenseLinesFrom.reduce((sum, cur) => sum + cur.totalAmount, 0);
      }

      if (expenseLinesTo) {
        totalRefundAmount.value += expenseLinesTo.reduce((sum, cur) => sum + cur.totalAmount, 0);
      }

      if (stipend) {
        totalRefundAmount.value += stipend.totalAmount;
      }
    };

    const loadRefundClaim = async () => {
      loading.value = true;
      await globalLoadingWrapper({ blocking: true }, async () => {
        const data = (await api.travelAndExpense.getCourseParticipantTravelAndExpense(refundClaimId)).data;

        getTotalAmount(data);

        rejectionComment.value = data.comment;

        mainFormValues.value = {
          ...data,
          expenseLinesFrom: data.expenseLinesFrom?.map((line) => ({
            ...line,
            attachmentIds: line.attachments?.map((x) => x.id) ?? [],
          })),
          expenseLinesTo: data.expenseLinesTo?.map((line) => ({
            ...line,
            attachmentIds: line.attachments?.map((x) => x.id) ?? [],
          })),
        };

        // If the status of the refund is "sent to approval" only show receipt
        if (data.status === TravelAndExpenseStatus.Draft || data.status === TravelAndExpenseStatus.Rejected) {
          if (data.status === TravelAndExpenseStatus.Rejected) {
            isRejected.value = true;
          }
          mainFormValues.value.selectedSteps = getSelectedSteps();
        } else {
          mainFormValues.value.selectedSteps = [RefundSteps.Summary];
          canEdit.value = false;
          currentStep.value = 5; // Set the current step to be the summary step
        }

        mainFormValues.value.expenseLinesFrom?.forEach((expenseLine) =>
          setAmountEmptyOnCostTypeWithoutPrice(expenseLine)
        );
        mainFormValues.value.expenseLinesTo?.forEach((expenseLine) => {
          setAmountEmptyOnCostTypeWithoutPrice(expenseLine);
        });

        // If diet is added to the expense we want to control that in a separate state. We add it back in to expense lines when we submit
        setDietExpense(dietToExpense, mainFormValues.value.expenseLinesTo);
        setDietExpense(dietFromExpense, mainFormValues.value.expenseLinesFrom);
      });

      loading.value = false;
    };

    const getCostTypes = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        costTypes.value = (await api.minside.getMinsideCosts(courseId, { CostTypeCostGroup: "utgifter" })).data;
      });
    };

    const getSelectedSteps = () => {
      // Hack
      if (refundClaimId) {
        const selectedSteps = [RefundSteps.General];
        if (
          "expenseLinesTo" in mainFormValues.value &&
          mainFormValues.value.expenseLinesTo &&
          mainFormValues.value.expenseLinesTo.length > 0
        ) {
          selectedSteps.push(RefundSteps.TravelTo);
          selectedSteps.push(RefundSteps.TravelFrom);
          selectedSteps.push(RefundSteps.Food);
        }

        if ("stipend" in mainFormValues.value) {
          selectedSteps.push(RefundSteps.Scholarship);
        }
        selectedSteps.push(RefundSteps.Summary);

        return selectedSteps;
      }
    };

    // When editing a refund, the price of the cost type is set in the amount of the expense line.
    // This is in order to persist the price at the time of the expense.
    // But if we want to edit a draft we can't submit amount for an expense line that has a cost type with a set price (sats)
    // We loop through the expense lines and set the amount to null where cost price !== 0
    const setAmountEmptyOnCostTypeWithoutPrice = (expenseLine?: ApiExpenseLineDto) => {
      const currentCostType = costTypes.value.find((costType) => costType.id === expenseLine?.costId);
      if (!currentCostType || !expenseLine) {
        return;
      }

      if (currentCostType.price !== 0) {
        expenseLine.amount = null;
      }
    };

    const setDietExpense = (dietExpenseState: Ref<ApiExpenseLineDto | undefined>, expenseLines?: any[] | null) => {
      let dietIndex = 0;
      const diet = expenseLines?.find((expense, index) => {
        const dietExpense = isDietCostType(expense.costId);
        if (dietExpense) {
          dietIndex = index;
        }

        return dietExpense;
      });

      if (diet) {
        dietExpenseState.value = diet;
        expenseLines?.slice(dietIndex, 1);
      }
    };

    const isDietCostType = (costId: number) => {
      const costTypesDiet = filterCostTypes(costTypes.value, dietTypes);

      return costTypesDiet.some((x) => x.id === costId);
    };

    return {
      rejectionComment,
      courseId,
      costTypes,
      currentStep,
      isFormsDisabled,
      mainFormValues,
      showReceiptPage,
      loading,
      RefundSteps,
      StepperDirections,
      dietToExpense,
      dietFromExpense,
      canEdit,
      switchToStep,
      submitForm,
      saveAndSendToApproval,
      updateFormState,
      getSelectedSteps,
      navigateBack: () => history.back(),
      totalRefundAmount,
      isRejected,
      defaultDates,
      isMobile,
      course,
      canSend,
    };
  },
});
