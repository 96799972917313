import { ApiGetCostDto, ApiExpenseLineDto } from "@/api/generated/Api";
import { TravelAndExpenseType } from "../enums/courseRefund.enum";

export const createExpenseLine = (costId?: number): ApiExpenseLineDto => {
  return {
    costId: costId || 0,
    attachmentIds: [],
    description: null,
    quantity: null,
    amount: null,
    origin: undefined,
    destination: undefined,
    providedBreakfastQuantity: 0,
    providedLunchQuantity: 0,
    providedDinnerQuantity: 0,
  };
};

export const travelTypes = [
  TravelAndExpenseType.GeneralTravelExpenses,
  TravelAndExpenseType.GeneralTravelExpensesWithOptionalReceipt,
  TravelAndExpenseType.TravelWithOwnVehicle,
  TravelAndExpenseType.TravelWithOwnVehicleRaisedRate,
];

export const dietTypes = [
  TravelAndExpenseType.DietBetweenSixAndTwelve,
  TravelAndExpenseType.DietOverTwelveHours,
  TravelAndExpenseType.DietWithAccomodation,
];

export const stipendTypes = [TravelAndExpenseType.Stipend];

export const filterCostTypes = (
  costTypes: ApiGetCostDto[],
  travelAndExpenseTypes: TravelAndExpenseType[]
): ApiGetCostDto[] => {
  return costTypes
    .filter(
      (costType) =>
        costType.travelAndExpenseType &&
        travelAndExpenseTypes.includes(costType.travelAndExpenseType as TravelAndExpenseType)
    )
    .filter((x) => x.isActive === true)
    .sort((a: any, b: any) => {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
    });
};
