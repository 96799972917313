var render = function () {
  var _vm$cost, _vm$cost2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.shouldDisplayCost ? _c('v-card', {
    staticClass: "mb-4 pa-4"
  }, [_c('h2', [_vm._t("expenseTitle")], 2), _vm.isVehicleCost ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('CourseSectionRefundAddressInput', {
    attrs: {
      "address": {
        origin: _vm.formValues.origin,
        destination: _vm.formValues.destination
      }
    },
    on: {
      "input": _vm.updateFormValues
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Kjørelengde",
      "suffix": "km",
      "rules": [_vm.validateNotEmpty]
    },
    model: {
      value: _vm.formValues.quantity,
      callback: function ($$v) {
        _vm.$set(_vm.formValues, "quantity", $$v);
      },
      expression: "formValues.quantity"
    }
  }), _c('legend', [_vm._v("Hadde du med deg passasjerer på reisen?")]), _c('fieldset', [_c('div', {
    staticClass: "pb-2"
  }, [_c('v-btn', {
    attrs: {
      "elevation": "2",
      "fab": "",
      "icon": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.amountOfCoTravelers--;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-minus")])], 1), _c('span', {
    staticClass: "pa-4"
  }, [_vm._v(_vm._s(_vm.amountOfCoTravelers))]), _c('v-btn', {
    attrs: {
      "elevation": "2",
      "fab": "",
      "icon": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.amountOfCoTravelers++;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1), _vm._l(_vm.passengersExpense, function (passenger, i) {
    return _c('div', {
      key: passenger.costId + i,
      staticClass: "pb-8"
    }, [_c('v-card', {
      staticClass: "pa-4"
    }, [_c('CourseSectionRefundAddressInput', {
      attrs: {
        "address": {
          origin: passenger.origin,
          destination: passenger.destination
        }
      },
      on: {
        "input": function (e) {
          var origin = e.origin,
            destination = e.destination;
          passenger.origin = origin;
          passenger.destination = destination;
        }
      },
      scopedSlots: _vm._u([{
        key: "coTravelerName",
        fn: function () {
          return [_c('v-col', {
            attrs: {
              "cols": "12"
            }
          }, [_c('label', [_vm._v("Passasjer " + _vm._s(i + 1))]), _c('v-text-field', {
            attrs: {
              "label": "Navn"
            },
            model: {
              value: passenger.description,
              callback: function ($$v) {
                _vm.$set(passenger, "description", $$v);
              },
              expression: "passenger.description"
            }
          })], 1)];
        },
        proxy: true
      }, {
        key: "coTravelerDistance",
        fn: function () {
          return [_c('v-col', {
            attrs: {
              "cols": "12"
            }
          }, [_c('v-text-field', {
            attrs: {
              "type": "number",
              "label": "Antall kilometer med passasjer",
              "suffix": "km"
            },
            model: {
              value: passenger.quantity,
              callback: function ($$v) {
                _vm.$set(passenger, "quantity", $$v);
              },
              expression: "passenger.quantity"
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    })], 1)], 1);
  })], 2)], 1)], 1) : _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_vm.displayAmountField ? _c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Beløp *",
      "suffix": "Kr",
      "rules": [_vm.validateNotEmpty]
    },
    model: {
      value: _vm.formValues.amount,
      callback: function ($$v) {
        _vm.$set(_vm.formValues, "amount", $$v);
      },
      expression: "formValues.amount"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('CourseRefundFileUpload', {
    attrs: {
      "attachmentIds": _vm.formValues.attachmentIds || [],
      "disable-validation": ((_vm$cost = _vm.cost) === null || _vm$cost === void 0 ? void 0 : _vm$cost.travelAndExpenseType) === _vm.TravelAndExpenseType.GeneralTravelExpensesWithOptionalReceipt,
      "label": ((_vm$cost2 = _vm.cost) === null || _vm$cost2 === void 0 ? void 0 : _vm$cost2.travelAndExpenseType) === _vm.TravelAndExpenseType.GeneralTravelExpensesWithOptionalReceipt ? "Kvittering" : "Kvittering *"
    },
    on: {
      "input:change": _vm.updateAttachments
    }
  })], 1), _c('v-textarea', {
    staticClass: "pa-3",
    attrs: {
      "name": "Kommentar",
      "label": _vm.textAreaLabel,
      "filled": "",
      "messages": _vm.cost ? _vm.cost.description : ''
    },
    model: {
      value: _vm.formValues.description,
      callback: function ($$v) {
        _vm.$set(_vm.formValues, "description", $$v);
      },
      expression: "formValues.description"
    }
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }