
import { defineComponent, PropType, ref, watch } from "@vue/composition-api";
import BaseInfoField from "@/components/shared/field/BaseInfoField.vue";
import { ApiExpenseLineDto, ApiGetCostDto, ApiGetExpenseLineDto } from "@/api/generated/Api";
import { ExtendedApiGetCourseParticipantTravelAndExpenseDto } from "@/pages/courses/CourseNewRefundClaimPage.vue";
import CourseRefundDisplayFile from "@/components/courses/section/CourseSectionRefund/RefundFormComponents/CourseRefundDisplayFile.vue";
import { formatLocalizedDate, formatLocalizedDateTime } from "@/shared/helpers/dateHelpers";
import { formatCurrency } from "@/shared/helpers/formattingHelpers";
import { RefundSteps, TravelAndExpenseStatus } from "@/shared/enums/courseRefund.enum";

export default defineComponent({
  name: "CourseRefundReceipt",
  components: { BaseInfoField, CourseRefundDisplayFile },
  props: {
    mainFormValues: {
      required: true,
      type: Object as PropType<ExtendedApiGetCourseParticipantTravelAndExpenseDto>,
    },
    refundStatus: {
      type: String as PropType<TravelAndExpenseStatus>,
      required: true,
    },
    costTypes: {
      type: Array as PropType<ApiGetCostDto[]>,
      required: true,
    },
    dietToExpense: {
      type: Object as PropType<ApiExpenseLineDto>,
    },
    dietFromExpense: {
      type: Object as PropType<ApiExpenseLineDto>,
    },
    totalRefundAmount: {
      type: Number,
    },
  },
  setup(props) {
    const hasScholarship = ref(
      props.refundStatus === TravelAndExpenseStatus.Approved ||
        props.refundStatus === TravelAndExpenseStatus.ToApproval ||
        props.refundStatus === TravelAndExpenseStatus.OrderCreated ||
        props.mainFormValues.selectedSteps?.includes(RefundSteps.Scholarship)
    );
    const hasTravel = ref(
      props.refundStatus === TravelAndExpenseStatus.Approved ||
        props.refundStatus === TravelAndExpenseStatus.ToApproval ||
        props.refundStatus === TravelAndExpenseStatus.OrderCreated ||
        props.mainFormValues.selectedSteps?.includes(RefundSteps.TravelTo || RefundSteps.TravelFrom)
    );

    watch(props, () => {
      hasScholarship.value =
        props.refundStatus === TravelAndExpenseStatus.Approved ||
        props.refundStatus === TravelAndExpenseStatus.ToApproval ||
        props.refundStatus === TravelAndExpenseStatus.OrderCreated ||
        props.mainFormValues.selectedSteps?.includes(RefundSteps.Scholarship);
      hasTravel.value =
        props.refundStatus === TravelAndExpenseStatus.Approved ||
        props.refundStatus === TravelAndExpenseStatus.ToApproval ||
        props.refundStatus === TravelAndExpenseStatus.OrderCreated ||
        props.mainFormValues.selectedSteps?.includes(RefundSteps.TravelTo || RefundSteps.TravelFrom);
    });

    const getTypeName = (costId: number) => {
      return (
        props.costTypes.find((costType) => {
          return costType.id === costId;
        })?.name ?? "Utgift"
      );
    };
    // Workaround
    const getAttachmentIds = (expenseLine: ApiExpenseLineDto | ApiGetExpenseLineDto) => {
      if ("attachmentIds" in expenseLine) {
        return expenseLine.attachmentIds;
      }
      if ("attachments" in expenseLine) {
        return expenseLine.attachments?.map((attachment) => attachment.id);
      }
      return [];
    };

    return {
      getTypeName,
      getAttachmentIds,
      formatLocalizedDate,
      formatLocalizedDateTime,
      formatCurrency,
      hasScholarship,
      hasTravel,
    };
  },
});
