var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isMobile ? _c('v-breadcrumbs', {
    staticClass: "py-0 pb-2",
    attrs: {
      "data-cy": "breadcrumbs",
      "items": _vm.breadcrumbList,
      "large": ""
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }