
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import BaseInfoField from "@/components/shared/field/BaseInfoField.vue";
import { binaryApi } from "@/api/api";

export default defineComponent({
  name: "CourseRefundDisplayFile",
  components: { BaseInfoField },
  props: {
    fileId: {
      type: Number,
      required: true,
    },
    displayFileSize: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["input:change"],
  setup({ fileId }) {
    const file = ref<File>();
    const fileUrl = ref<string>();

    onMounted(async () => {
      if (fileId) {
        await getFile(fileId);
      }
    });

    const getFile = async (id: number) => {
      file.value = (await binaryApi.file.downloadFileAsync(id, { format: "blob" })).data;
      fileUrl.value = URL.createObjectURL(new Blob([file.value], { type: file.value?.type }));
    };

    const getObjectURL = (file: File) => {
      return file ? URL.createObjectURL(file) : "#";
    };

    const getSize = (file: File) => {
      const size = file.size / 1048576;
      return size > 0.5 ? size.toFixed(2) + " mb" : file.size + " bytes";
    };

    return {
      file,
      fileUrl,
      getSize,
      getObjectURL,
    };
  },
});
