var render = function () {
  var _vm$formValues;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_vm.costTypes ? _c('v-form', {
    ref: "newRefundClaimForm",
    attrs: {
      "disabled": _vm.isFormDisabled
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [[_c('v-combobox', {
    attrs: {
      "items": _vm.mainCostType,
      "item-text": "name",
      "label": "Hva skal du ha refusjon for?",
      "rules": [_vm.validateNotEmpty],
      "small-chips": "",
      "clearable": "",
      "multiple": ""
    },
    model: {
      value: _vm.selectedCostTypes,
      callback: function ($$v) {
        _vm.selectedCostTypes = $$v;
      },
      expression: "selectedCostTypes"
    }
  })], (_vm$formValues = _vm.formValues) !== null && _vm$formValues !== void 0 && _vm$formValues.length ? _vm._l(_vm.formValues, function (expense, i) {
    return _c('div', {
      key: expense.costId + i
    }, [_c('CourseRefundAlternativeExpense', {
      attrs: {
        "expense": expense,
        "cost": _vm.getCostById(expense.costId),
        "costIdsNotToBeDisplayed": _vm.costIdsNotToBeDisplayed,
        "costTypesVehicle": _vm.costTypesVehicle,
        "passengers": _vm.passengers,
        "displayAmountField": _vm.shouldDisplayAmountField(expense.costId)
      },
      on: {
        "input:change": _vm.updateFormValues,
        "addPassenger": _vm.addPassenger,
        "removePassenger": _vm.removePassenger,
        "updatePassengerList": _vm.updatePassengerList
      },
      scopedSlots: _vm._u([{
        key: "expenseTitle",
        fn: function () {
          return [_c('label', {
            staticClass: "mb-4 d-block"
          }, [_vm._v(_vm._s(_vm.getCostTypeName(expense.costId)))])];
        },
        proxy: true
      }], null, true)
    })], 1);
  }) : _vm._e(), _vm.showFoodSection ? _c('CourseRefundDiet', {
    attrs: {
      "editValues": _vm.dietExpense,
      "isInboundTravel": _vm.isInboundTravel,
      "costTypesDiet": _vm.costTypesDiet,
      "defaultDates": _vm.defaultDates
    },
    on: {
      "input": function (e) {
        return _vm.dietChanged(e);
      }
    }
  }) : _vm._e()], 2)], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('previous-step');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-left")]), _vm._v(" Tilbake ")], 1), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" Neste "), _c('v-icon', [_vm._v("mdi-arrow-right")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }