var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_vm.displayBreadCrumbs ? _c('BaseBreadCrumbs') : _vm._e(), _c('v-card', {
    attrs: {
      "elevation": 2
    }
  }, [_vm.displayToolbar ? _c('v-toolbar', {
    attrs: {
      "flat": "",
      "dark": "",
      "color": "primary"
    },
    scopedSlots: _vm._u([_vm.displayTabs ? {
      key: "extension",
      fn: function () {
        return [_vm._t("tabs")];
      },
      proxy: true
    } : null], null, true)
  }, [_c('v-toolbar-title', [_vm._t("title")], 2), _c('v-spacer'), _vm._t("actions")], 2) : _vm._e(), _vm._t("default"), _vm.displayCardActions ? [_c('v-card-actions', [_vm._t("cardActions")], 2)] : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }