
import { ApiExpenseLineDto, ApiGetCostDto } from "@/api/generated/Api";
import CourseSectionRefundAddressInput from "@/components/courses/section/CourseSectionRefund/RefundFormComponents/CourseRefundAddressInput.vue";
import CourseRefundAlternativeExpense from "@/components/courses/section/CourseSectionRefund/RefundFormComponents/CourseRefundAlternativeExpense.vue";
import CourseRefundDiet from "@/components/courses/section/CourseSectionRefund/RefundFormSections/CourseRefundDiet.vue";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import { TravelAndExpenseType } from "@/shared/enums/courseRefund.enum";
import { createExpenseLine, dietTypes, filterCostTypes, travelTypes } from "@/shared/helpers/courseRefundHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateBoolean, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { PropType, computed, defineComponent, onMounted, ref, watch } from "@vue/composition-api";

export default defineComponent({
  name: "CourseRefundToAndFrom",
  components: {
    CourseRefundDiet,
    CourseRefundAlternativeExpense,
    BaseDatePicker,
    CourseSectionRefundAddressInput,
  },
  props: {
    costTypes: {
      type: Array as PropType<ApiGetCostDto[]>,
      required: true,
    },
    isInboundTravel: {
      default: true,
      type: Boolean,
    },
    isFormDisabled: {
      required: true,
      type: Boolean,
    },
    showFoodSection: {
      type: Boolean,
      default: false,
    },
    editValues: {
      type: Array as PropType<ApiExpenseLineDto[]>,
      required: false,
    },
    dietExpense: {
      type: Object as PropType<ApiExpenseLineDto | undefined>,
    },
    defaultDates: {
      type: Object,
      required: true,
    },
  },
  emits: ["submit", "previousStep", "dietChanged"],
  setup({ isInboundTravel, editValues, costTypes }, { emit, refs }) {
    const formValues = ref<ApiExpenseLineDto[]>([]);

    onMounted(async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        if (editValues) {
          formValues.value = editValues;
          selectedCostTypes.value = setSelectedCostTypes();
          passengers.value = formValues.value.filter((expense) =>
            costTypesPassenger.value.some((x) => x.id === expense.costId)
          );
        }
      });
    });

    const selectedCostTypes = ref<ApiGetCostDto[]>();

    const passengers = ref<ApiExpenseLineDto[]>([]);

    const mainCostType = computed(() => {
      return filterCostTypes(costTypes, travelTypes);
    });

    const costTypesDiet = computed(() => {
      return filterCostTypes(costTypes, dietTypes);
    });

    const costTypesPassenger = computed(() => {
      return filterCostTypes(costTypes, [TravelAndExpenseType.PassengerCompensation]);
    });

    const costTypesVehicle = computed(() => {
      return costTypes.filter(
        (costType) =>
          costType.travelAndExpenseType === TravelAndExpenseType.TravelWithOwnVehicleRaisedRate ||
          costType.travelAndExpenseType === TravelAndExpenseType.TravelWithOwnVehicle
      );
    });

    const getCostById = (id: number) => costTypes.find((cost) => cost.id === id);

    const getCostTypeName = (id: number) => {
      const cost = getCostById(id);
      return cost ? cost.name : id;
    };

    const shouldDisplayAmountField = (id: number) => {
      const cost = getCostById(id);
      return cost ? cost.price === 0 : true;
    };

    const setSelectedCostTypes = () => {
      if (formValues.value) {
        return formValues.value
          .map((expense) => {
            const match = costTypes.find((costType) => costType.id === expense.costId);
            if (match && !costIdsNotToBeDisplayed.value.includes(match.id)) {
              return match;
            }
          })
          .filter(Boolean) as ApiGetCostDto[];
      }
      return [];
    };

    watch(
      () => selectedCostTypes.value,
      () => {
        formValues.value = selectedCostTypes.value?.map((cost) => {
          if (formValues.value) {
            const expense = formValues.value.find((expense: any) => expense.costId === cost.id);
            if (expense) {
              return expense;
            }
            return createExpenseLine(cost.id);
          }
        }) as ApiExpenseLineDto[];
      }
    );

    const dietChanged = (newDiet: ApiExpenseLineDto) => {
      emit("dietChanged", newDiet);
    };

    const submit = () => {
      if (getValidatableRef(refs.newRefundClaimForm)?.validate() && formValues.value) {
        formValues.value.push(...passengers.value);
        emit(
          "submit",
          isInboundTravel ? { expenseLinesTo: [...formValues.value] } : { expenseLinesFrom: [...formValues.value] }
        );
      }
    };

    const updateFormValues = (value: any) => {
      if (formValues.value) {
        const indexOfItemToUpdate = formValues.value.findIndex(
          (expense: ApiExpenseLineDto) => expense.costId === value.costId
        );

        if (indexOfItemToUpdate !== -1) {
          formValues.value[indexOfItemToUpdate] = value;
        }
      }
    };

    const addPassenger = () => {
      if (costTypes) {
        // We just grab the first passenger cost type here. If the user should be able to select it we need to implement this.
        const costType = costTypesPassenger.value[0];
        if (costType) {
          passengers.value.push(createExpenseLine(costType.id));
        }
      }
    };

    const removePassenger = () => {
      passengers.value.pop();
    };

    const updatePassengerList = (list: ApiExpenseLineDto[]) => {
      passengers.value = list;
    };

    const costIdsNotToBeDisplayed = computed(() => {
      return [
        ...costTypesPassenger.value.map((costType) => costType.id),
        ...costTypesDiet.value.map((costType) => costType.id),
      ];
    });

    return {
      validateNotEmpty,
      validateBoolean,
      submit,
      updateFormValues,
      addPassenger,
      removePassenger,
      updatePassengerList,
      getCostTypeName,
      shouldDisplayAmountField,
      getCostById,
      dietChanged,
      costIdsNotToBeDisplayed,
      mainCostType,
      costTypesDiet,
      costTypesVehicle,
      formValues,
      selectedCostTypes,
      passengers,
    };
  },
});
