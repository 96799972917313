
import { validateNotEmpty, validateAccountNumber, validateBoolean } from "@/shared/helpers/validationHelpers";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import { computed, defineComponent, PropType, ref, watch } from "@vue/composition-api";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { RefundSteps } from "@/shared/enums/courseRefund.enum";

export default defineComponent({
  name: "CourseRefundGeneral",
  components: { BaseDatePicker },
  props: {
    isFormDisabled: {
      required: true,
      type: Boolean,
    },
    courseId: {
      type: Number,
      required: true,
    },
    selectedSteps: {
      type: Array as PropType<string[]>,
    },
    bankAccountNo: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  emits: ["submit"],
  setup({ courseId, selectedSteps, bankAccountNo, description }, { emit, refs }) {
    const disableNextButton = ref<boolean>(false);
    const travel = ref<boolean>(false);
    const food = ref<boolean>(false);
    const scholarship = ref<boolean>(false);
    const validateCheckboxes = computed<boolean>(() => travel.value || food.value || scholarship.value);

    const formValues = ref({
      courseId,
      bankAccountNo: bankAccountNo,
      description: description,
    });

    const submit = () => {
      if (getValidatableRef(refs.newRefundClaimForm)?.validate()) {
        const allSteps = {
          [RefundSteps.General]: true,
          [RefundSteps.TravelTo]: travel.value,
          [RefundSteps.TravelFrom]: travel.value,
          [RefundSteps.Food]: food.value,
          [RefundSteps.Scholarship]: scholarship.value,
          [RefundSteps.Summary]: true,
        };
        const newArray = [];
        for (const [key, value] of Object.entries(allSteps)) {
          if (value !== false) {
            newArray.push(key);
          }
        }
        emit("submit", { ...formValues.value, selectedSteps: selectedSteps ? selectedSteps : newArray });
      }
    };

    watch(travel, () => (food.value = travel.value ? food.value : false));

    return {
      submit,
      validateBoolean,
      validateNotEmpty,
      validateAccountNumber,
      validateCheckboxes,
      disableNextButton,
      scholarship,
      formValues,
      travel,
      food,
    };
  },
});
