
import { defineComponent } from "@vue/composition-api";
import BaseBreadCrumbs from "@/components/shared/breadcrumbs/BaseBreadCrumbs.vue";

export default defineComponent({
  name: "BaseLayout",
  components: {
    BaseBreadCrumbs,
  },
  props: {
    displayTabs: {
      type: Boolean,
      default: false,
    },
    displayCardActions: {
      type: Boolean,
      default: false,
    },
    displayBreadCrumbs: {
      type: Boolean,
      default: true,
    },
    displayToolbar: {
      type: Boolean,
      default: true,
    },
  },
});
