var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "scholarshipForm"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('BaseDatePicker', {
    attrs: {
      "label": "Dato fra",
      "value": _vm.formValues.fromDate,
      "validateNotEmpty": true
    },
    on: {
      "input": function (date) {
        return _vm.formValues.fromDate = date;
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('BaseDatePicker', {
    attrs: {
      "label": "Dato til",
      "value": _vm.formValues.toDate,
      "validateNotEmpty": true
    },
    on: {
      "input": function (date) {
        return _vm.formValues.toDate = date;
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.costTypesStipend,
      "item-text": "name",
      "item-value": "id",
      "label": "Velg utgift du vil registrere stipend på?",
      "rules": [_vm.validateNotEmpty]
    },
    model: {
      value: _vm.formValues.costId,
      callback: function ($$v) {
        _vm.$set(_vm.formValues, "costId", $$v);
      },
      expression: "formValues.costId"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('v-card', {
    staticClass: "mb-4 pa-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Antall timer tapt arbeidsfortjeneste *",
      "messages": "(bruk punktum som desimalskilletegn)",
      "rules": [_vm.validateNotEmpty]
    },
    model: {
      value: _vm.formValues.hours,
      callback: function ($$v) {
        _vm.$set(_vm.formValues, "hours", $$v);
      },
      expression: "formValues.hours"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('CourseRefundFileUpload', {
    attrs: {
      "attachmentIds": _vm.formValues.attachmentIds || [],
      "label": "Vedlegg (pdf, jpeg, jpg, png)",
      "helpText": "Legg ved signert dokumentasjon fra arbeidsgiver på tapt arbeidsfortjeneste"
    },
    on: {
      "input:change": _vm.updateAttachments
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('v-textarea', {
    staticClass: "pa-3",
    attrs: {
      "name": "Kommentar",
      "label": "Kommentar",
      "filled": ""
    },
    model: {
      value: _vm.formValues.description,
      callback: function ($$v) {
        _vm.$set(_vm.formValues, "description", $$v);
      },
      expression: "formValues.description"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('previous-step');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-left")]), _vm._v(" Tilbake ")], 1), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" Neste "), _c('v-icon', [_vm._v("mdi-arrow-right")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }